import React, { useEffect, useState } from "react";
import { subject as a } from '@casl/ability';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';
import { useHistory } from "react-router-dom";

import illustration from '../assets/images/kuvitus_tyotilaus.png';
import './css/dashboard.style.css';
import AppLoader from './appLoader.component';
import Can from './can.component';
import DashboardCard from "./dashboardCard.component";
import LocationForm from "./forms/locationForm.component";
import LocationItem from "./locationItem.component";
import MessageForm from "./forms/messageForm.component";
import MessageItem from "./messageItem.component";
import Modal from "./modal.component";
import NewsForm from "./forms/newsForm.component";
import NewsItem from "./newsItem.component";
import Page from "./page.component";
import UploadFiles from "./upload-files.component";
import UserForm from "./forms/userForm.component";
import UserItem from "./userItem.component";
import LocationDataService from '../services/location.service';
import MessageDataService from '../services/message.service';
import NewsDataService from '../services/news.service';
import UserDataService from '../services/user.service';
import AuthService from '../services/auth.service';
import UploadService from '../services/upload-files.service';
import { UserRole } from '../enums';
import { hasRequiredRoles } from '../utils';
import WorkorderForm from './forms/workorderForm.component';
import UserLocationsForm from "./forms/userLocationsForm";

const cleaningInfo = [
  {
    id: 3,
    label: 'Aineet'
  }
];

export default function Dashboard() {
  const history = useHistory();
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingLocations, setIsFetchingLocations] = useState(true);
  const [isFetchingMessages, setIsFetchingMessages] = useState(true);
  const [isFetchingNews, setIsFetchingNews] = useState(true);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);

  const [locations, setLocations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [news, setNews] = useState([]);
  const [users, setUsers] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const handleShowLocationModal = () => setShowLocationModal(true);
  const handleHideLocationModal = () => setShowLocationModal(false);
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const handleShowCreateLocationModal = () => setShowCreateLocationModal(true);
  const handleHideCreateLocationModal = () => setShowCreateLocationModal(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const handleShowEditLocationModal = () => setShowEditLocationModal(true);
  const handleHideEditLocationModal = () => setShowEditLocationModal(false);
  const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
  const handleShowDeleteLocationModal = () => setShowDeleteLocationModal(true);
  const handleHideDeleteLocationModal = () => setShowDeleteLocationModal(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const handleShowMessageModal = () => setShowMessageModal(true);
  const handleHideMessageModal = () => setShowMessageModal(false);
  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);
  const handleShowCreateMessageModal = () => setShowCreateMessageModal(true);
  const handleHideCreateMessageModal = () => setShowCreateMessageModal(false);
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const handleShowEditMessageModal = () => setShowEditMessageModal(true);
  const handleHideEditMessageModal = () => setShowEditMessageModal(false);
  const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
  const handleShowDeleteMessageModal = () => setShowDeleteMessageModal(true);
  const handleHideDeleteMessageModal = () => setShowDeleteMessageModal(false);
  const [showNewsModal, setShowNewsModal] = useState(false);
  const handleShowNewsModal = () => setShowNewsModal(true);
  const handleHideNewsModal = () => setShowNewsModal(false);
  const [showCreateNewsModal, setShowCreateNewsModal] = useState(false);
  const handleShowCreateNewsModal = () => setShowCreateNewsModal(true);
  const handleHideCreateNewsModal = () => setShowCreateNewsModal(false);
  const [showEditNewsModal, setShowEditNewsModal] = useState(false);
  const handleShowEditNewsModal = () => setShowEditNewsModal(true);
  const handleHideEditNewsModal = () => setShowEditNewsModal(false);
  const [showDeleteNewsModal, setShowDeleteNewsModal] = useState(false);
  const handleShowDeleteNewsModal = () => setShowDeleteNewsModal(true);
  const handleHideDeleteNewsModal = () => setShowDeleteNewsModal(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const handleShowUserModal = () => setShowUserModal(true);
  const handleHideUserModal = () => setShowUserModal(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const handleShowCreateUserModal = () => setShowCreateUserModal(true);
  const handleHideCreateUserModal = () => setShowCreateUserModal(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleShowEditUserModal = () => setShowEditUserModal(true);
  const handleHideEditUserModal = () => setShowEditUserModal(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const handleShowDeleteUserModal = () => setShowDeleteUserModal(true);
  const handleHideDeleteUserModal = () => setShowDeleteUserModal(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleHideUploadModal = () => setShowUploadModal(false);
  const [showUserLocationsModal, setShowUserLocationsModal] = useState(false);
  const handleShowUserLocationsModal = () => setShowUserLocationsModal(true);
  const handleHideUserLocationsModal = () => setShowUserLocationsModal(false);

  async function handleCreateLocation(values) {
    try {
      setIsSendingData(true);
      await LocationDataService.create(values);
      setIsSendingData(false);
      const response = await LocationDataService.getAll();
      const data = response.data.map(location => a('Location', location));
      setLocations(data);
      setShowCreateLocationModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  function handleClick() {
    history.push("/workorders");
  }

  async function handleUpdateLocation(values) {
    try {
      setIsSendingData(true);
      await LocationDataService.update(selectedLocation.id, values);
      setIsSendingData(false);
      const response = await LocationDataService.getAll();
      const data = response.data.map(location => a('Location', location));
      setLocations(data);
      setShowEditLocationModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteLocation() {
    try {
      setIsSendingData(true);
      await LocationDataService.delete(selectedLocation.id);
      setSelectedLocation(null);
      setIsSendingData(false);
      const response = await LocationDataService.getAll();
      const data = response.data.map(location => a('Location', location));
      setLocations(data);
      setShowDeleteLocationModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleCreateMessage(values) {
    try {
      setIsSendingData(true);
      await MessageDataService.create(values);
      setIsSendingData(false);
      const response = await MessageDataService.getAll();
      const data = response.data.map(message => a('Message', message));
      setMessages(data);
      setShowCreateMessageModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateMessage(values) {
    try {
      setIsSendingData(true);
      await MessageDataService.update(selectedMessage.id, values);
      setIsSendingData(false);
      const response = await MessageDataService.getAll();
      const data = response.data.map(message => a('Message', message));
      setMessages(data);
      setShowEditMessageModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteMessage() {
    try {
      setIsSendingData(true);
      await MessageDataService.delete(selectedMessage.id);
      setSelectedMessage(null);
      setIsSendingData(false);
      const response = await MessageDataService.getAll();
      const data = response.data.map(message => a('Message', message));
      setMessages(data);
      setShowDeleteMessageModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleCreateNews(values) {
    try {
      setIsSendingData(true);
      await NewsDataService.create(values);
      setIsSendingData(false);
      const response = await NewsDataService.getAll();
      const data = response.data.map(news => a('News', news));
      setNews(data);
      setShowCreateNewsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateNews(values) {
    try {
      setIsSendingData(true);
      await NewsDataService.update(selectedNews.id, values);
      setIsSendingData(false);
      const response = await NewsDataService.getAll();
      const data = response.data.map(news => a('News', news));
      setNews(data);
      setShowEditNewsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteNews() {
    try {
      setIsSendingData(true);
      await NewsDataService.delete(selectedNews.id);
      setSelectedNews(null);
      setIsSendingData(false);
      const response = await NewsDataService.getAll();
      const data = response.data.map(news => a('News', news));
      setNews(data);
      setShowDeleteNewsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleCreateUser(values) {
    try {
      setIsSendingData(true);
      await UserDataService.create(values);
      setIsSendingData(false);
      const response = await UserDataService.getAll();
      const data = response.data.map(user => a('User', user));
      setUsers(data);
      setShowCreateUserModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateUser(values) {
    try {
      const updates = values.password ? values : _.omit(values, ['password', 'confirmPassword']);

      setIsSendingData(true);
      await UserDataService.update(selectedUser.id, updates);
      setIsSendingData(false);
      const response = await UserDataService.getAll();
      const data = response.data.map(user => a('User', user));
      setUsers(data);
      setShowEditUserModal(false);
      setShowUserLocationsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteUser() {
    try {
      setIsSendingData(true);
      await UserDataService.delete(selectedUser.id);
      setSelectedUser(null);
      setIsSendingData(false);
      const response = await UserDataService.getAll();
      const data = response.data.map(user => a('User', user));
      setUsers(data);
      setShowDeleteUserModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  useEffect(() => {
    async function getLocations() {
      try {
        setIsFetchingLocations(true);
        const response = hasRequiredRoles([UserRole.ADMIN]) ?
          await LocationDataService.getAll() :
          await LocationDataService.getAllByCustomer(AuthService.getCurrentUser().customerId);
        const data = response.data.map(location => a('Location', location));
        setLocations(data);
        setIsFetchingLocations(false);
      } catch(error) {
        console.log(error);
        setIsFetchingLocations(false);
      }
    }
    getLocations();

    async function getMessages() {
      try {
        setIsFetchingMessages(true);
        const response = await MessageDataService.getAll();
        const data = response.data.map(message => a('Message', message));
        setMessages(data);
        setIsFetchingMessages(false);
      } catch(error) {
        console.log(error);
        setIsFetchingMessages(false);
      }
    }
    getMessages();

    async function getNews() {
      try {
        setIsFetchingNews(true);
        var response = null;
        if(AuthService.getCurrentUser().roles[0] == "ROLE_ADMIN"){
          response = await NewsDataService.getAll();
        } else {
          response = await NewsDataService.getAllByCustomer(AuthService.getCurrentUser().customerId);
        }
        const data = response.data.map(news => a('News', news));
        setNews(data);
        setIsFetchingNews(false);
      } catch(error) {
        console.log(error);
        setIsFetchingNews(false);
      }
    }
    getNews();

    async function getUsers() {
      try {
        setIsFetchingUsers(true);
        const response = await UserDataService.getAll();
        const data = response.data.map(users => a('User', users));
        setUsers(data);
        setIsFetchingUsers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingUsers(false);
      }
    }
    getUsers();

    async function getFiles() {
      try {
        setIsFetchingFiles(true);
        const response = await UploadService.getFiles();
        setFileInfos(response.data);
        setIsFetchingFiles(false);
      } catch(error) {
        console.log(error);
        setIsFetchingFiles(false);
      }
    }
    getFiles();
  }, []);

  return (
    <Page>
        <AppLoader
          active={ isFetchingLocations || isFetchingMessages || isFetchingNews || isFetchingUsers || isFetchingFiles }
          component={ true } />

        <h3 className="mb-5">Tervetuloa!</h3>
        <div className="dashboard-row">
          <Can I="read" a="News">
            <DashboardCard title="Uutiset" resource="News" onClick={ handleShowCreateNewsModal }>
              { news.map(item => (
                <Can I="read" this={item}>
                  <NewsItem
                    key={ item.id }
                    item={ item }
                    onSelect={ setSelectedNews }
                    onShow={ handleShowNewsModal }
                    onShowEdit={ handleShowEditNewsModal }
                    onShowDelete={ handleShowDeleteNewsModal }
                    extract={ true } />
                </Can>
              )) }
            </DashboardCard>
          </Can>
          <Can I="read" a="Location">
            <DashboardCard title="Kohteet" resource="Location" onClick={ handleShowCreateLocationModal }>
              { locations.map(location => (
                <Can I="read" this={location}>
                  <LocationItem
                    key={ location.id }
                    location={ location }
                    onSelect={ setSelectedLocation }
                    onShow={ handleShowLocationModal }
                    onShowEdit={ handleShowEditLocationModal }
                    onShowDelete={ handleShowDeleteLocationModal } />
                </Can>
              ))}
            </DashboardCard>
          </Can>
          <Can I="create" a="Workorder">
            <DashboardCard title="Tee uusi työtilaus" resource="Workorder" onClick={ handleClick } borderless reverseHeader>
              <div className="dashboard-illustration-wrapper">
                <img className="dashboard-illustration" src={ illustration } alt="" />
              </div>
            </DashboardCard>
          </Can>
          <Can I="read" a="Message">
            <DashboardCard title="Viestit" resource="Message" onClick={ handleShowCreateMessageModal }>
              { messages.map(message => (
                <Can I="read" this={message}>
                  <MessageItem
                    key={ message.id }
                    message={ message }
                    onSelect={ setSelectedMessage }
                    onShow={ handleShowMessageModal }
                    onShowEdit={ handleShowEditMessageModal }
                    onShowDelete={ handleShowDeleteMessageModal } />
                </Can>
              )) }
            </DashboardCard>
          </Can>
          <Can I="read" a="Method">
            <DashboardCard title="Siivousmetodit" resource="Method" onClick={ () => console.log('click dashboardcard') }>
              { cleaningInfo.map(item => (
                <div key={ item.id }>
                  <Link to={ `/cleaning-info/${ item.id }`}>{ item.label }</Link>
                </div>
              ))}
            </DashboardCard>
          </Can>
          <Can I="create" a="Quality">
            <DashboardCard title="Laadunvalvonta">
              <Button
                variant="primary mt-5"
                onClick={ handleShowUploadModal }>
                Lähetä kuva
              </Button>
            </DashboardCard>
          </Can>
          <Can I="read" a="User">
            <DashboardCard title="Käyttäjät" resource="User" onClick={ handleShowCreateUserModal }>
              { users.map(user => (
                <Can I="read" this={user}>
                  <UserItem
                    key={ user.id }
                    user={ user }
                    onSelect={ setSelectedUser }
                    onShow={ handleShowUserModal }
                    onShowEdit={ handleShowEditUserModal }
                    onShowDelete={ handleShowDeleteUserModal }
                    onShowLocations={ handleShowUserLocationsModal }
                  />
                </Can>
              )) }
            </DashboardCard>
          </Can>
        </div>

        <Modal
          title="Kohde"
          show={ showLocationModal }
          onHide={ handleHideLocationModal }
        >
          <LocationItem location={ selectedLocation } />
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideLocationModal }>
              Sulje
            </Button>
          </div>
        </Modal>
        <Modal
          title="Lisää kohde"
          show={ showCreateLocationModal }
          onHide={ handleHideCreateLocationModal }
        >
          <LocationForm
            onCancel={ handleHideCreateLocationModal }
            onSubmit={ handleCreateLocation }
          />
        </Modal>
        <Modal
          title="Muokkaa kohdetta"
          show={ showEditLocationModal }
          onHide={ handleHideEditLocationModal }
        >
          <LocationForm
            location={selectedLocation}
            isSendingData={ isSendingData }
            onCancel={ handleHideEditLocationModal }
            onSubmit={ handleUpdateLocation }
          />
        </Modal>
        <Modal
          title="Poista kohde"
          show={ showDeleteLocationModal }
          onHide={ handleHideDeleteLocationModal }
        >
          <LocationItem location={ selectedLocation } />
          <p>Haluatko varmasti poistaa tämän kohteen? Tätä toimintoa ei voi peruuttaa.</p>
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideDeleteLocationModal }>
              Peruuta
            </Button>
            <Button className="ml-3" variant="danger" onClick={ handleDeleteLocation }>
              Kyllä
            </Button>
          </div>
        </Modal>
        <Modal
          title="Viesti"
          size="lg"
          show={ showMessageModal }
          onHide={ handleHideMessageModal }
        >
          <MessageItem message={ selectedMessage } />
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideMessageModal }>
              Sulje
            </Button>
          </div>
        </Modal>
        <Modal
          title="Lisää viesti"
          size="lg"
          show={ showCreateMessageModal }
          onHide={ handleHideCreateMessageModal }
        >
          <MessageForm
            isSendingData={ isSendingData }
            onCancel={ handleHideCreateMessageModal }
            onSubmit={ handleCreateMessage }
          />
        </Modal>
        <Modal
          title="Muokkaa viestiä"
          size="lg"
          show={ showEditMessageModal }
          onHide={ handleHideEditMessageModal }
        >
          <MessageForm
            message={selectedMessage}
            isSendingData={ isSendingData }
            onCancel={ handleHideEditMessageModal }
            onSubmit={ handleUpdateMessage }
          />
        </Modal>
        <Modal
          title="Poista viesti"
          size="lg"
          show={ showDeleteMessageModal }
          onHide={ handleHideDeleteMessageModal }
        >
          <MessageItem item={ selectedMessage } />
          <p>Haluatko varmasti poistaa tämän viestin? Tätä toimintoa ei voi peruuttaa.</p>
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideDeleteMessageModal }>
              Peruuta
            </Button>
            <Button className="ml-3" variant="danger" onClick={ handleDeleteMessage }>
              Kyllä
            </Button>
          </div>
        </Modal>
        <Modal
          title="Uutinen"
          size="lg"
          show={ showNewsModal }
          onHide={ handleHideNewsModal }
        >
          <NewsItem item={ selectedNews } />
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideNewsModal }>
              Sulje
            </Button>
          </div>
        </Modal>
        <Modal
          title="Lisää uutinen"
          size="lg"
          show={ showCreateNewsModal }
          onHide={ handleHideCreateNewsModal }
        >
          <NewsForm
            isSendingData={ isSendingData }
            onCancel={ handleHideCreateNewsModal }
            onSubmit={ handleCreateNews }
          />
        </Modal>
        <Modal
          title="Muokkaa uutista"
          size="lg"
          show={ showEditNewsModal }
          onHide={ handleHideEditNewsModal }
        >
          <NewsForm
            item={selectedNews}
            isSendingData={ isSendingData }
            onCancel={ handleHideEditNewsModal }
            onSubmit={ handleUpdateNews }
          />
        </Modal>
        <Modal
          title="Poista uutinen"
          size="lg"
          show={ showDeleteNewsModal }
          onHide={ handleHideDeleteNewsModal }
        >
          <NewsItem item={ selectedNews } />
          <p>Haluatko varmasti poistaa tämän uutisen? Tätä toimintoa ei voi peruuttaa.</p>
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideDeleteNewsModal }>
              Peruuta
            </Button>
            <Button className="ml-3" variant="danger" onClick={ handleDeleteNews }>
              Kyllä
            </Button>
          </div>
        </Modal>
        <Modal
          title="Käyttäjä"
          show={ showUserModal }
          onHide={ handleHideUserModal }
        >
          <UserItem user={ selectedUser } />
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideUserModal }>
              Sulje
            </Button>
          </div>
        </Modal>
        <Modal
          title="Lisää käyttäjä"
          show={ showCreateUserModal }
          onHide={ handleHideCreateUserModal }
        >
          <UserForm
            isSendingData={ isSendingData }
            onCancel={ handleHideCreateUserModal }
            onSubmit={ handleCreateUser }
          />
        </Modal>
        <Modal
          title="Muokkaa käyttäjää"
          show={ showEditUserModal }
          onHide={ handleHideEditUserModal }
        >
          <UserForm
            user={ selectedUser }
            isSendingData={ isSendingData }
            onCancel={ handleHideEditUserModal }
            onSubmit={ handleUpdateUser }
          />
        </Modal>
        <Modal
          title="Muokkaa käyttäjän kohteita"
          show={ showUserLocationsModal }
          onHide={ handleHideUserLocationsModal }
        >
          <UserLocationsForm
            user={ selectedUser }
            isSendingData={ isSendingData }
            onCancel={ handleHideUserLocationsModal }
            onSubmit={ handleUpdateUser }
          />
        </Modal>
        <Modal
          title="Poista käyttäjä"
          show={ showDeleteUserModal }
          onHide={ handleHideDeleteUserModal }
        >
          <UserItem user={ selectedUser } />
          <p>Haluatko varmasti poistaa tämän käyttäjän? Tätä toimintoa ei voi peruuttaa.</p>
          <div className="align-right mt-4">
            <Button variant="secondary" onClick={ handleHideDeleteUserModal }>
              Peruuta
            </Button>
            <Button className="ml-3" variant="danger" onClick={ handleDeleteUser }>
              Kyllä
            </Button>
          </div>
        </Modal>
        <Modal
          title="Lisää tiedosto"
          show={ showUploadModal }
          onHide={ handleHideUploadModal }
        >
          <UploadFiles
            onCancel={ handleHideUploadModal } />
        </Modal>
    </Page>
  )
}
