import React, { Component } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Dashboard from "./components/dashboard.component";
import CleaningInfo from "./components/cleaningInfo.component";
import NewsList from "./components/news.component";
import AddCustomer from "./components/add-customer.component";
import Customers from "./components/customers.component";
import CustomersList from "./components/customers-list.component";
import AddLocation from "./components/add-location.component";
import Location from "./components/location.component";
import Locations from "./components/locations.component";
import LocationsList from "./components/locations-list.component";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import ResetPassword from "./components/resetPassword.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardAdmin from "./components/board-admin.component";
import DesktopNavigation from "./components/desktopNavigation.component";
import MobileNavigation from "./components/mobileNavigation.component";
import PrivateRoute from "./components/privateRoute.component";
import NotFound from "./components/notFound.component";
import Unauthorized from "./components/unauthorized.component";
import QualityControl from "./components/qualityControl.component";
import Messages from "./components/messages.component";
import { UserRole } from "./enums";
import Workorders from "./components/workorders.component";
import Tools from "./components/tools.component";
import Chemicals from "./components/chemicals.component";
import Workmanuals from "./components/workmanuals.component";
import Workingways from "./components/workingways.component";
import Privacy from "./components/privacy.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showAdminBoard: false,
      currentUser: undefined,
      isLoading: true
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        isLoading: false
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  logOut() {
    console.log('logging out');
    AuthService.logout();
  }

  render() {
    const { showAdminBoard, isLoading } = this.state;

    return (
      <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/">
            <MobileNavigation />
            <div className="app-wrapper">
              <DesktopNavigation
                onLogout={ this.logOut }
              />
              <div className="app-content">
                <Switch>
                  <Route path="/privacy">
                    <Privacy />
                  </Route>
                  <Route exact path={"/"}>
                    <Redirect to="/login" />
                    {/* <Home /> */}
                  </Route>
                  <PrivateRoute exact path={["/dashboard"]}>
                    <Dashboard />
                  </PrivateRoute>
                  <PrivateRoute exact path="/login">
                    <Login />
                  </PrivateRoute>
                  <PrivateRoute exact path="/register">
                    <Register />
                  </PrivateRoute>
                  <PrivateRoute exact path="/profile">
                    <Profile />
                  </PrivateRoute>
                  <Route exact path="/auth/reset_password/:token">
                    <ResetPassword />
                  </Route>
                  <PrivateRoute exact path="/cleaning-info" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <CleaningInfo />
                  </PrivateRoute>
                  <PrivateRoute exact path="/cleaning-info/1" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Workingways />
                  </PrivateRoute>
                  <PrivateRoute exact path="/cleaning-info/2" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Workmanuals />
                  </PrivateRoute>
                  <PrivateRoute exact path="/cleaning-info/3" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Chemicals />
                  </PrivateRoute>
                  <PrivateRoute exact path="/cleaning-info/4" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Tools />
                  </PrivateRoute>
                  <PrivateRoute exact path="/news" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <NewsList />
                  </PrivateRoute>
                  <PrivateRoute exact path="/quality" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <QualityControl />
                  </PrivateRoute>
                  <PrivateRoute exact path="/messages" permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Messages />
                  </PrivateRoute>
                  <PrivateRoute exact path={["/customers"]} permittedRoles={[UserRole.ADMIN]}>
                    <Customers />
                  </PrivateRoute>
                  <PrivateRoute exact path={["/workorders"]} permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Workorders />
                  </PrivateRoute>
                  <PrivateRoute exact path={["/locations"]} permittedRoles={[UserRole.CUSTOMER, UserRole.SUPERVISOR, UserRole.ADMIN]}>
                    <Locations />
                  </PrivateRoute>
                  <PrivateRoute exact path="/addCustomer" permittedRoles={[UserRole.ADMIN]}>
                    <AddCustomer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/addLocation" permittedRoles={[UserRole.ADMIN]}>
                    <AddLocation />
                  </PrivateRoute>
                  <PrivateRoute path="/customers/:id" permittedRoles={[UserRole.ADMIN]}>
                    {/* <Customer /> */}
                    <h1>single customer</h1>
                  </PrivateRoute>
                  <PrivateRoute path="/locations/:id" permittedRoles={[UserRole.CUSTOMER]}>
                    <Location />
                  </PrivateRoute>
                  <PrivateRoute path="/user">
                    <BoardUser />
                  </PrivateRoute>
                  <PrivateRoute path="/admin">
                    <BoardAdmin />
                  </PrivateRoute>
                  <Route path="/unauthorized" component={ Unauthorized }>
                  </Route>
                  <Route component={ NotFound }>
                  </Route>
                </Switch>
              </div>
            </div>
          </Route>
      </Switch>


    );
  }
}

export default App;
