import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import Modal from './modal.component';
import NewsForm from './forms/newsForm.component';
import NewsItem from './newsItem.component';
import Page from './page.component';
import NewsDataService from '../services/news.service';

import './css/news.style.css';

export default function News() {
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingNews, setIsFetchingNews] = useState(true);

  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);

  const [showNewsModal, setShowNewsModal] = useState(false);
  const handleShowNewsModal = () => setShowNewsModal(true);
  const handleHideNewsModal = () => setShowNewsModal(false);
  const [showCreateNewsModal, setShowCreateNewsModal] = useState(false);
  const handleShowCreateNewsModal = () => setShowCreateNewsModal(true);
  const handleHideCreateNewsModal = () => setShowCreateNewsModal(false);
  const [showEditNewsModal, setShowEditNewsModal] = useState(false);
  const handleShowEditNewsModal = () => setShowEditNewsModal(true);
  const handleHideEditNewsModal = () => setShowEditNewsModal(false);
  const [showDeleteNewsModal, setShowDeleteNewsModal] = useState(false);
  const handleShowDeleteNewsModal = () => setShowDeleteNewsModal(true);
  const handleHideDeleteNewsModal = () => setShowDeleteNewsModal(false);

  async function handleCreateNews(values) {
    try {
      setIsSendingData(true);
      await NewsDataService.create(values);
      setIsSendingData(false);
      const response = await NewsDataService.getAll();
      const data = response.data.map(news => a('News', news));
      setNews(data);
      setShowCreateNewsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateNews(values) {
    try {
      setIsSendingData(true);
      await NewsDataService.update(selectedNews.id, values);
      setIsSendingData(false);
      const response = await NewsDataService.getAll();
      const data = response.data.map(news => a('News', news));
      setNews(data);
      setShowEditNewsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteNews() {
    try {
      setIsSendingData(true);
      await NewsDataService.delete(selectedNews.id);
      setSelectedNews(null);
      setIsSendingData(false);
      const response = await NewsDataService.getAll();
      const data = response.data.map(news => a('News', news));
      setNews(data);
      setShowDeleteNewsModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  useEffect(() => {
    async function getNews() {
      try {
        setIsFetchingNews(true);
        const response = await NewsDataService.getAll();
        const data = response.data.map(news => a('News', news));
        setNews(data);
        setIsFetchingNews(false);
      } catch(error) {
        console.log(error);
        setIsFetchingNews(false);
      }
    }
    getNews();
  }, []);

  return (
    <Page className="news-content">
      <AppLoader active={ isFetchingNews } component={ true } />

      <div className="news-layout">
        <div className="text">
          <Can I="create" a="News">
            <div className="vertical-center mt-5 mb-5 ml-5">
              <span className="mb-0 button" onClick={ handleShowCreateNewsModal }>Luo uusi uutinen
              <ActionIcon
                className="ml-5"
                icon={ faPlusCircle }
              />
              </span>
            </div>
          </Can>
          <h2 className="mb-5">Uutiset</h2>
          <Can I="read" a="News">
            <>
              { news && news.map(item => (
                <Can I="read" this={item}>
                  <NewsItem
                    key={ item.id }
                    item={ item }
                    onSelect={ setSelectedNews }
                    onShow={ handleShowNewsModal }
                    onShowEdit={ handleShowEditNewsModal }
                    onShowDelete={ handleShowDeleteNewsModal } />
                </Can>
              )) }
            </>
          </Can>
        </div>
      </div>

      <Modal
        title="Uutinen"
        size="lg"
        show={ showNewsModal }
        onHide={ handleHideNewsModal }
      >
        <NewsItem item={ selectedNews } />
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideNewsModal }>
            Sulje
          </Button>
        </div>
      </Modal>
      <Modal
        title="Lisää uutinen"
        size="lg"
        show={ showCreateNewsModal }
        onHide={ handleHideCreateNewsModal }
      >
        <NewsForm
          onCancel={ handleHideCreateNewsModal }
          onSubmit={ handleCreateNews }
        />
      </Modal>
      <Modal
        title="Muokkaa uutista"
        size="lg"
        show={ showEditNewsModal }
        onHide={ handleHideEditNewsModal }
      >
        <NewsForm
          item={ selectedNews }
          isSendingData={ isSendingData }
          onCancel={ handleHideEditNewsModal }
          onSubmit={ handleUpdateNews }
        />
      </Modal>
      <Modal
        title="Poista uutinen"
        size="lg"
        show={ showDeleteNewsModal }
        onHide={ handleHideDeleteNewsModal }
      >
        <NewsItem item={ selectedNews } />
        <p>Haluatko varmasti poistaa tämän viestin? Tätä toimintoa ei voi peruuttaa.</p>
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideDeleteNewsModal }>
            Peruuta
          </Button>
          <Button className="ml-3" variant="danger" onClick={ handleDeleteNews }>
            Kyllä
          </Button>
        </div>
      </Modal>
    </Page>
  );
}
