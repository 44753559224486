import React from "react";
import authService from "../services/auth.service";
import { Redirect } from "react-router-dom";

/**
 * Page to display when user is unauthorized to access
 */
export default function Unauthorized() {
  const user = authService.getCurrentUser();

  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <h3>Ei oikeutta tarkastella resurssia</h3>
  );
}
