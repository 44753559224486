import React, { useContext, useRef, useState } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { AbilityBuilder } from '@casl/ability';
import authService from '../services/auth.service';
import { useHistory } from 'react-router-dom';
import AppLoader from './appLoader.component';
import { AbilityContext } from './can.component';
import ResetPasswordForm from './resetpasswordForm.component';

import { UserRole } from '../enums';

function updateAbility(ability, user) {
    const { can, rules } = new AbilityBuilder();
    for (let i = 0; i < user.roles.length; i++) {
        if (user.roles[i] === UserRole.ADMIN) {
            can('manage', 'Customer');
            can('manage', 'Location');
            can('manage', 'Method');
            can('manage', 'News');
            can('manage', 'Quality');
            can('manage', 'User');
            can('manage', 'Message');
            can('manage', 'Workorder');
            can('manage', 'Tool');
            can('manage', 'Chemical');
            can('manage', 'Workmanual');
            can('manage', 'Workingway');
        } else if (user.roles[i] === UserRole.SUPERVISOR) {
            can('read', 'Location', { id: { $in: user.locations } });
            can('read', 'Material');
            can('create', 'Message');
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
            can('read', 'Quality');
            can('read', 'Workorder');
            can('read', 'Tool');
        } else if (user.roles[i] === UserRole.SALES) {
            can('create', 'Location');
            can('create', 'Customer');
            can('read', 'Material');
            can('create', 'Message');
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
            can('read', 'Quality');
            can('read', 'Workorder');
            can('read', 'Tool');
        } else if (user.roles[i] === UserRole.CUSTOMER_ADMIN) {
            can('read', 'Location', { id: { $in: user.locations } });
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('create', 'User');
            can(['read', 'update'], 'User', ['username', 'email', 'password'], { customerId: user.customerId });
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
        } else if (user.roles[i] === UserRole.CUSTOMER) {
            can('read', 'Location', { id: { $in: user.locations } });
            can('create', 'Message');
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
            // can('read', 'Method');
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('read', 'News', { userId: user.id });
            can('read', 'Quality');
            can('read', 'Workorder', { assignee: 'me' });
            can(['read','create','update'], 'Workorder', { id: { $in: user.locations } });
            can('read', 'Tool');
            can('read', 'Chemical');
            can('read', 'Workmanual');
            can('read', 'Workingway');
        }
    }
    ability.update(rules);
}

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
};

export default function LoginForm() {
    const formRef = useRef();
    const buttonRef = useRef();
    const history = useHistory();
    const ability = useContext(AbilityContext);
    const [ isError, setIsError ] = useState();
    const [ isLoading, setIsLoading ] = useState();
    const [ password, setPassword ] = useState();
    const [ username, setUsername ] = useState();
    const [ showResetPasswordForm, setShowResetPasswordForm ] = useState(false);

    function handleLogin(e) {
        e.preventDefault();

        setIsError(false);
        setIsLoading(true);

        formRef.current.validateAll();

        if (buttonRef.current.context._errors.length === 0) {
          authService.login(username, password).then(
            (user) => {
              updateAbility(ability, user);

              history.push("/dashboard");
            },
            error => {
                setIsError(true);
                setIsLoading(false);
            }
          );
        } else {
          setIsLoading(false);
        }
      }

    function onChangeUsername(e) {
        setUsername(e.target.value);
      }

    function onChangePassword(e) {
        setPassword(e.target.value);
    }

    return (
        <div className="card card-container">
            <AppLoader active={ isLoading } component={ true } />
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />

            <Form
              onSubmit={handleLogin}
              ref={ formRef }
            >
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={ username }
                  onChange={ onChangeUsername }
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={ password }
                  onChange={ onChangePassword }
                  validations={[required]}
                />
              </div>

              {isError &&
                <div className="alert alert-danger" role="alert">
                  Virheellinen käyttäjätunnus tai salasana!
                </div>
              }

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={ isLoading}
                >
                  <span>Login</span>
                </button>
              </div>

              <CheckButton
                style={{ display: "none" }}
                ref={ buttonRef }
              />
            </Form>
            <div className="form-group">
                
                { showResetPasswordForm ?
                  <ResetPasswordForm /> :
                  <a href="#" onClick={ () => setShowResetPasswordForm(true) }>Salasana unohtunut?</a>
                }
              </div>
        </div>
    )
}
