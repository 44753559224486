import React, { useRef, useState } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import authService from '../services/auth.service';
import AppLoader from './appLoader.component';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
};

export default function ResetPasswordForm() {
    const formRef = useRef();
    const buttonRef = useRef();
    const [ isLoading, setIsLoading ] = useState();
    const [ email, setEmail ] = useState();

    function handleResetPassword(e) {
        e.preventDefault();

        setIsLoading(true);

        formRef.current.validateAll();

        if (buttonRef.current.context._errors.length === 0) {
          authService.forgotPassword(email).then(
            (data) => {
              console.log(data);
              setIsLoading(false);
            },
            error => {
              console.log(error);
              setIsLoading(false);
            }
          );
        } else {
          setIsLoading(false);
        }
      }

    function onChangeEmail(e) {
        setEmail(e.target.value);
      }

    return (
      <div className="form">
          <AppLoader active={ isLoading } component={ true } />

          <Form
            onSubmit={handleResetPassword}
            ref={ formRef }
          >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={ email }
                onChange={ onChangeEmail }
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={ isLoading}
              >
                <span>Reset</span>
              </button>
            </div>

            <CheckButton
              style={{ display: "none" }}
              ref={ buttonRef }
            />
          </Form>
      </div>
    )
}
