import React, { useState } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import './css/qualityItem.style.css';
import QualityForm from './forms/qualityForm.component';
import Modal from './modal.component';
import QualityDataService from '../services/quality.service';
import { subject as a } from '@casl/ability';

export default function QualityItem({ quality, onSelect, onShow, onShowEdit, onShowDelete }) {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingQualities, setIsFetchingQualities] = useState(true);

    const [qualities, setQualities] = useState([]);

    const [showCreateQualityModal, setShowCreateQualityModal] = useState(false);
    const handleShowCreateQualityModal = () => setShowCreateQualityModal(true);
    const handleHideCreateQualityModal = () => setShowCreateQualityModal(false);

    async function handleCreateQuality(values) {
        try {
          setIsSendingData(true);
          await QualityDataService.create(values);
          setIsSendingData(false);
          const response = await QualityDataService.getAll();
          const data = response.data.map(quality => a('Quality', quality));
          setQualities(data);
          setShowCreateQualityModal(false);
        } catch(error) {
          console.log(error);
          setIsSendingData(false);
        }
      }

    function handleShow() {
        if (onSelect) onSelect(quality);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(quality);
        onShowEdit();
    }

    function handleShowReply() {
        let tmp = quality;
        tmp.title = "VS: "+quality.title;
        tmp.quality = "";
        quality = tmp;
        handleShowCreateQualityModal();
    }

    function handleShowDelete() {
        onSelect(quality);
        onShowDelete();
    }

    function isUpdated() {
        return quality && moment(quality.updatedAt).isAfter(moment(quality.createdAt));
    }

    return (
        <div className="quality-item">
            <div className="quality-item-content-wrapper" onClick={ handleShow }>
                <div className="quality-from-avatar"></div>
                <div className="quality-item-content">
                    <h6>{quality && quality.title }</h6>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            <p dangerouslySetInnerHTML={quality && { __html: quality.quality }}></p>
                            <p className="mt-2"><small>Luotu: {quality && moment(quality.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: {quality && moment(quality.updatedAt).format('lll') }</small></p> }
                        </> }
                </div>
            </div>
            <div className="quality-item-actions">
                { onSelect && onShowEdit && <Can I="update" this={quality}>
                    <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </Can> }
                { onSelect && onShowDelete && <Can I="delete" this={quality}>
                    <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Can> }
            </div>
        </div>
    );
}
