import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import Can from './can.component';
import './css/dashboardCard.style.css';

export default function DashboardCard({
    children,
    onClick,
    title,
    resource,
    borderless = false,
    reverseHeader = false
}) {
    return (
        <div className={ `dashboard-card ${ borderless ? '' : 'border' }` }>
            <div className={ `dashboard-card-header ${ reverseHeader ? 'flex-row-reverse' : '' }` }>
                <h3 onClick={ onClick }>{ title }</h3>
                <Can I="create" a={resource}>
                    <FontAwesomeIcon
                        className="dashboard-card-header-icon"
                        icon={faPlusCircle}
                        onClick={ onClick }                        
                    />
                </Can>
            </div>
            <div className="dashboard-card-body">
                { children }
            </div>
        </div>
    )
}
