import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "./modal.component";
import Page from "./page.component";
import ChangePasswordForm from "./forms/changePasswordForm.component";
import userService from "../services/user.service";

import "./css/profile.style.css";

export default function Profile() {
  const [isSendingData, setIsSendingData] = useState(false);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);
  const handleHideChangePasswordModal = () => setShowChangePasswordModal(false);

  const user = JSON.parse(window.localStorage.getItem('user'));

  async function handleChangePassword(values) {
    try {
      setIsSendingData(true);
      await userService.update(user.id, values);
      setIsSendingData(false);
      handleHideChangePasswordModal();
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  return (
    <Page className="profile-content">
      <div className="profile-layout">
        <div className="text">
          <h2 className="mb-5">Profiili</h2>
          <h6>Käyttäjänimi</h6>
          <p>{user.username}</p>
          <h6>Sähköpostiosoite</h6>
          <p>{user.email}</p>
          <Button
            variant="primary mt-2"
            onClick={ handleShowChangePasswordModal }>
            Vaihda salasana
          </Button>

          <Modal
            title="Vaihda salasana"
            show={ showChangePasswordModal }
            onHide={ handleHideChangePasswordModal }
          >
            <ChangePasswordForm
              isSendingData={ isSendingData }
              onCancel={ handleHideChangePasswordModal }
              onSubmit={ handleChangePassword }
            />
          </Modal>
        </div>
      </div>
    </Page>
  );
}
