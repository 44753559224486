import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { buildAbilityFor } from "./config/ability.config";
import { AbilityContext } from "./components/can.component";
import * as serviceWorker from "./serviceWorker";

const ability = buildAbilityFor('ROLE_CUSTOMER');

if (process.env.NODE_ENV !== 'production') {
  // expose ability to play around with it in devtools
  window.ability = ability;
}

ReactDOM.render(
  <AbilityContext.Provider value={ability}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AbilityContext.Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
