import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import './css/locationTools.style.css';

export default function LocationTools({ tools }) {
  const [selectedTools, setSelectedTools] = useState([]);

  function handleToggleSelectTool(tool) {
    if (selectedTools.some((selectedTool) => selectedTool.name === tool.name)) {
      setSelectedTools(selectedTools.filter((selectedTool) => selectedTool.name !== tool.name));
    } else {
      setSelectedTools([...selectedTools, tool]);
    }
  }

  function isSelectedTool(tool) {
    return selectedTools.some((selectedTool) => selectedTool.name === tool.name);
  }

  return (
    <>
      {console.log(tools)}
      <ListGroup>
        { tools && tools.map((tool, index) => (
          <ListGroup.Item
            key={ index }
            onClick={ () => handleToggleSelectTool(tool) }>
            <div className="list-group-item-content">
              <a href={ tool.url } target="_blank" rel="noopener noreferrer">
                 {tool.name}
              </a>
              {
                <div className={ `list-group-item-checkbox ${ isSelectedTool(tool) && 'active' }` }>
                  {
                    isSelectedTool(tool) ?
                      <FontAwesomeIcon icon={faCheckSquare} /> :
                      <FontAwesomeIcon icon={faSquare} />
                  }
                </div>
              }
            </div>
          </ListGroup.Item>
        )) }
      </ListGroup>
    </>
  );
}
