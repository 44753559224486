import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import ReactQuill from 'react-quill';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';
import Can from '../can.component';
import locationService from '../../services/location.service';

import 'react-quill/dist/quill.snow.css';

const validationSchema = Yup.object({
  title: Yup.string()
    .required(errors.form.required),
  message: Yup.string()
    .required(errors.form.required)
});

export default function MessageForm({
  onSubmit,
  onCancel,
  isSendingData,
  message
}) {
  const [isFetchingLocations, setIsFetchingLocations] = useState(true);
  const [locations, setLocations] = useState();

  useEffect(() => {
    async function getLocations() {
      try {
        setIsFetchingLocations(true);
        const response = await locationService.getAll();
        const data = response.data.map(location => a('Location', location));
        setLocations(data);
        setIsFetchingLocations(false);
      } catch(error) {
        console.log(error);
        setIsFetchingLocations(false);
      }
    }
    getLocations();
  }, []);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


  return (
    <>
      <AppLoader active={ isFetchingLocations || isSendingData } component={ true } />
      <Formik
        initialValues={{
          locationId: message ? message.locationId : '',
          title: message ? message.title : '',
          message: message ? message.message : ''
        }}
        validationSchema={ validationSchema}
        onSubmit={ onSubmit }
      >
        { props  => (
          <Form
            noValidate
            onSubmit={ props.handleSubmit }>
              <TextInput
                name="title"
                label="Otsikko"
                placeholder="Otsikko"
                formik={ props }
                required
              />
              <Form.Group>
                  <Form.Label>Viesti</Form.Label>
                  <Field name="message">
                    {({ field }) => <ReactQuill modules={modules} value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
              </Form.Group>
              <Can I="read" a="Location">
                <Form.Group>
                  <Form.Label>Viestin kohde</Form.Label>
                  <Form.Control
                    as="select"
                    name="locationId"
                    value={ props.values.locationId }
                    onChange={ props.handleChange }
                    onBlur={ props.handleBlur }
                  >
                      <option value="">Valitse kohde</option>
                      { locations && locations.map(location => (
                        <Can I="read" this={location}>
                          <option key={ location.id } value={ location.id }>
                            { location.name }
                          </option>
                        </Can>)) }
                  </Form.Control>
                </Form.Group>
              </Can>
            <div className="align-right mt-4">
              <Button
                onClick={ () => {
                  onCancel();
                }}
                className="mr-3"
                type="button"
                variant="secondary"
              >
                Peruuta
              </Button>
              <Button
                type="submit"
              >
                Lähetä
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
