import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Nav, ListGroup } from 'react-bootstrap';
import logo from '../assets/images/logo-blim.png';

import './css/navigation.style.css';
import Can from './can.component';
import authService from '../services/auth.service';

export default function DesktopNavigation({
    onLogout
}) {
  const location = useLocation();
  const user = authService.getCurrentUser();

  return (
    <div className="desktop-nav bg-primary">
      <div className="desktop-nav-logo">
        <img src={ logo } alt="Logo" />
      </div>
      <Nav
        activeKey={ location.pathname }
        className="flex-column"
      >
        <ListGroup className="desktop-navigation-items">
          { user &&
            <>
              <ListGroup.Item
                as={ NavLink }
                to="/dashboard"
                action
              >
                Dashboard
              </ListGroup.Item>
              <Can I="read" a="Quality">
                <ListGroup.Item
                  as={ NavLink }
                  to="/quality"
                  action
                >
                  Laadunvalvonta
                </ListGroup.Item>
              </Can>
              <Can I="read" a="Location">
                <ListGroup.Item
                  as={ NavLink }
                  to="/locations"
                  action
                >
                  Kohteet
                </ListGroup.Item>
              </Can>
              <Can I="read" a="Message">
                <ListGroup.Item
                  as={ NavLink }
                  to="/messages"
                  action
                >
                  Viestit
                </ListGroup.Item>
              </Can>
              <Can I="read" a="Customer">
                <ListGroup.Item
                  as={ NavLink }
                  to="/customers"
                  action
                >
                  Asiakkaat
                </ListGroup.Item>
              </Can>
              <Can I="read" a="Method">
                <ListGroup.Item
                  as={ NavLink }
                  to="/cleaning-info"
                  action
                >
                  Siivousmetodit
                </ListGroup.Item>
              </Can>
              <Can I="read" a="News">
                <ListGroup.Item
                  as={ NavLink }
                  to="/news"
                  action
                >
                  Uutiset
                </ListGroup.Item>
              </Can>
              <Can I="read" a="Workorder">
                <ListGroup.Item
                  as={ NavLink }
                  to="/workorders"
                  action
                >
                  Työtilaus
                </ListGroup.Item>
              </Can>
              <Can I="read" a="Info">
                <ListGroup.Item
                  as={ NavLink }
                  to="/info"
                  action
                >
                  Esittely
                </ListGroup.Item>
              </Can>
              <ListGroup.Item
                as={ NavLink }
                to="/profile"
                action
              >
                Profiili
              </ListGroup.Item>
              <ListGroup.Item
                as={ NavLink }
                to="/login"
                action
                onClick={ onLogout }
              >
                Kirjaudu ulos
              </ListGroup.Item>
            </>
          }
        </ListGroup>
      </Nav>

    {/* <nav className="navbar nav-tabs bg-primary flex-column align-items-start">
      <Link to={"/home"} className="nav-link text-light">
        MTB
      </Link>

        {showAdminBoard && (
          <li className="nav-item">
            <Link to={"/admin"} className="nav-link text-light">
              Admin työkalut
            </Link>
          </li>
        )}

      </div>

      {currentUser ? (
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/profile"} className="nav-link text-light">
              {currentUser.username}
            </Link>
          </li>
          <li className="nav-item">
            <a href="/login" className="nav-link text-light" onClick={ onLogout }>
              LogOut
            </a>
          </li>
        </div>
      ) : (
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/login"} className="nav-link text-light">
              Kirjaudu
            </Link>
          </li>

          <li className="nav-item">
            <Link to={"/register"} className="nav-link text-light">
              Rekisteröidy
            </Link>
          </li>
        </div>
      )}
    </nav> */}
    </div>
  );
}
