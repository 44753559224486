import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Nav, ListGroup, Button } from 'react-bootstrap';
import logo from '../assets/images/logo-blim.png';

import './css/navigation.style.css';
import Can from './can.component';
import authService from '../services/auth.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function MobileNavigation({
    onLogout
}) {
  const location = useLocation();
  const user = authService.getCurrentUser();
  const [ show, setShow ] = useState(false);

  function handleShowMenu() {
    setShow(true);
    document.body.style.position = 'fixed';
  }

  function handleHideMenu() {
    setShow(false);
    document.body.style.position = '';
  }

  return (
    <div className="mobile-nav bg-primary">
      <div className="mobile-nav-header">
        <div className="mobile-nav-logo">
          <img src={ logo } alt="Logo" />
        </div>
        <div>
          <Button
            className="mobile-nav-toggle"
            onClick={ handleShowMenu }
            variant="secondary"
          >
              <FontAwesomeIcon icon={ faBars } />
          </Button>
        </div>
      </div>
      <div
        className={ `mobile-nav-menu ${ show ? 'active' : '' }`}
        onClick={ handleHideMenu }
      >
        <Nav
          activeKey={ location.pathname }
          className="flex-column"
        >
          <div className="mobile-navigation-items-header">
            <Button
              className="mobile-nav-toggle"
              onClick={ handleShowMenu }
              variant="secondary"
            >
                <FontAwesomeIcon size="2x" icon={ faTimes } />
            </Button>
          </div>
          <ListGroup className="mobile-navigation-items">
            { user &&
              <>
                <ListGroup.Item
                  as={ NavLink }
                  to="/dashboard"
                  action
                >
                  Dashboard
                </ListGroup.Item>
                <Can I="read" a="Quality">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/quality"
                    action
                  >
                    Laadunvalvonta
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="Location">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/locations"
                    action
                  >
                    Kohteet
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="Message">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/messages"
                    action
                  >
                    Viestit
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="Customer">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/customers"
                    action
                  >
                    Asiakkaat
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="Method">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/cleaning-info"
                    action
                  >
                    Siivousmetodit
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="News">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/news"
                    action
                  >
                    Uutiset
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="Work">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/workorders"
                    action
                  >
                    Työtilaus
                  </ListGroup.Item>
                </Can>
                <Can I="read" a="Info">
                  <ListGroup.Item
                    as={ NavLink }
                    to="/info"
                    action
                  >
                    Esittely
                  </ListGroup.Item>
                </Can>
                <ListGroup.Item
                  as={ NavLink }
                  to="/profile"
                  action
                >
                  Profiili
                </ListGroup.Item>
                <ListGroup.Item
                  as={ NavLink }
                  to="/login"
                  action
                  onClick={ onLogout }
                >
                  Kirjaudu ulos
                </ListGroup.Item>
              </>
            }
          </ListGroup>
        </Nav>
      </div>


    </div>
  );
}
