import React, { useState } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import './css/messageItem.style.css';
import MessageForm from './forms/messageForm.component';
import Modal from './modal.component';
import MessageDataService from '../services/message.service';
import { subject as a } from '@casl/ability';

export default function MessageItem({ message, onSelect, onShow, onShowEdit, onShowDelete }) {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingMessages, setIsFetchingMessages] = useState(true);

    const [messages, setMessages] = useState([]);

    const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);
    const handleShowCreateMessageModal = () => setShowCreateMessageModal(true);
    const handleHideCreateMessageModal = () => setShowCreateMessageModal(false);

    async function handleCreateMessage(values) {
        try {
          setIsSendingData(true);
          await MessageDataService.create(values);
          setIsSendingData(false);
          const response = await MessageDataService.getAll();
          const data = response.data.map(message => a('Message', message));
          setMessages(data);
          setShowCreateMessageModal(false);
        } catch(error) {
          console.log(error);
          setIsSendingData(false);
        }
      }

    function handleShow() {
        if (onSelect) onSelect(message);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(message);
        onShowEdit();
    }

    function handleShowReply() {
        let tmp = message;
        tmp.title = "VS: "+message.title;
        tmp.message = "";
        message = tmp;
        handleShowCreateMessageModal();
    }

    function handleShowDelete() {
        onSelect(message);
        onShowDelete();
    }

    function isUpdated() {
        return message && moment(message.updatedAt).isAfter(moment(message.createdAt));
    }

    return (
        <div className="message-item">
            <div className="message-item-content-wrapper" onClick={ handleShow }>
                <div className="message-from-avatar"></div>
                <div className="message-item-content">
                    <h6><b>Lähettäjä:</b> {message && message.sender.name }, <b>Kohde:</b> {message && message.location.name }, <b>Aihe:</b> {message && message.title }</h6>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            <p dangerouslySetInnerHTML={message && { __html: message.message }}></p>
                            <p className="mt-2"><small>Luotu: {message && moment(message.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: {message && moment(message.updatedAt).format('lll') }</small></p> }
                            <div className="message-item-actions">
                                <Can I="reply" this={message}>
                                    <Button onClick={ handleShowReply }>
                                        Vastaa viestiin <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                </Can>
                            </div>
                        </> }
                </div>
            </div>
            <div className="message-item-actions">
                { onSelect && onShowEdit && <Can I="update" this={message}>
                    <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </Can> }
                { onSelect && onShowDelete && <Can I="delete" this={message}>
                    <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Can> }
            </div>
            <Modal
                title="Vastaa viestiin"
                size="lg"
                show={ showCreateMessageModal }
                onHide={ handleHideCreateMessageModal }
            >
                <MessageForm
                    message={message}
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateMessageModal }
                    onSubmit={ handleCreateMessage }
                />
            </Modal>
        </div>
    );
}
