import React, { useState, useEffect } from 'react';
import { subject as a } from '@casl/ability';
import ReactQuill from 'react-quill';
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';
import Can from '../can.component';
import customerService from '../../services/customer.service';
import UploadService from "../../services/upload-files.service";

import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import userService from '../../services/user.service';

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (<img src={thumb}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={100}
      width={100} />);
  }
}

const validationSchema = Yup.object({
  name: Yup.string()
    .required(errors.form.required),
  address: Yup.string()
});

export default function LocationForm({
  onSubmit,
  onCancel,
  location
}) {
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);
  const [isFetchingManagers, setIsFetchingManagers] = useState(true);
  const [customers, setCustomers] = useState();
  const [error, setError] = useState(null);
  const [managers, setManagers] = useState();

  useEffect(() => {
    (async function getCustomers() {
      try {
        setIsFetchingCustomers(true);
        const response = await customerService.getAll();
        const data = response.data.map(customer => a('Customer', customer));
        setCustomers(data);
        setIsFetchingCustomers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingCustomers(false);
      }
    })();
    (async function getManagers() {
      try {
        setIsFetchingManagers(true);
        const response = await userService.getManagers();
        const data = response.data.map(manager => a('User', manager));
        setManagers(data);
        setIsFetchingManagers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingManagers(false);
      }
    })();
  }, []);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  class Thumb extends React.Component {
    state = {
      loading: false,
      thumb: undefined,
    };
  
    UNSAFE_componentWillReceiveProps (nextProps) {
      if (!nextProps.file) { return; }
  
      this.setState({ loading: true }, () => {
        this.setState({ loading: false, thumb: nextProps.file });
      });
    }
  
    render() {
      const { file } = this.props;
      const { loading, thumb } = this.state;
      if (!file) { return null; }
    
      if (loading) { return <p>loading...</p>; }
  
      return (<img src={file}
        className="img-thumbnail mt-2"
        height={120}
        width={120} />);
    }
  }

  async function handleUpload(categoryId, locationId, file) {
    const payload = { categoryId, locationId, file: file };
    try {
      console.log(payload);
      setIsSendingData(true);
      await UploadService.upload(payload);
    } catch (error) {
      console.log(error);
      setError('Kuvan lisääminen ei onnistunut.');
    } finally {
      setIsSendingData(false);
    }
  }

  return (
    <>
      <AppLoader
        active={ isFetchingCustomers || isFetchingManagers || isSendingData }
        component={ true }
      />
      <Formik
        initialValues={{
          name: location ? location.name : '',
          description: location ? location.description : '',
          tools: location ? location.tools : '',
          ways: location ? location.ways : '',
          address: location ? location.address : '',
          worker1: location ? location.worker1 : '',
          worker2: location ? location.worker2 : '',
          worker1img: location ? location.worker1img : '',
          worker2img: location ? location.worker2img : '',
          worker1imgtmp: '',
          worker2imgtmp: '',
          customerId: location ? location.customerId : '',
          managers: location ? location.managers.map(({ id, name }) => ({ label: name, value: id })) : []
        }}
        validationSchema={ validationSchema}
        onSubmit={ (values) => {
          let { managers } = values;
          managers = managers.map(manager => manager.value);
          onSubmit({
            ...values,
            managers
          });
        }}
      >
        { props  => (
          <Form
            noValidate
            onSubmit={ props.handleSubmit }>
              <TextInput
                name="name"
                label="Kohteen nimi"
                placeholder="Kohteen nimi"
                formik={ props }
                required
              />
              <TextInput
                as="textarea"
                name="address"
                label="Kohteen osoite"
                placeholder="Kohteen osoite"
                formik={ props }
              />
              <Form.Group>
                  <Form.Label>Esittely/huomioitavaa</Form.Label>
                  <Field name="description">
                    {({ field }) => <ReactQuill modules={modules} formats={formats} value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
              </Form.Group>
              <Form.Group>
                  <Form.Label>Työvälineet</Form.Label>
                  <Field name="tools">
                    {({ field }) => <ReactQuill modules={modules} formats={formats} value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
              </Form.Group>
              <Form.Group>
                  <Form.Label>Työtavat</Form.Label>
                  <Field name="ways">
                    {({ field }) => <ReactQuill modules={modules} formats={formats} value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
              </Form.Group>
              <TextInput
                name="worker1"
                label="Kohteen siivooja"
                placeholder="Kohteen siivooja"
                formik={ props }
              />
              <Form.Group>
                <Form.Label>Kohteen siivooja (kuva)</Form.Label>
                <input id="worker1img" name="worker1img" type="file" onChange={(event) => {
                  handleUpload(66,location.id,event.currentTarget.files[0]);
                  props.setFieldValue("worker1imgtmp", event.currentTarget.files[0]);
                  props.setFieldValue("worker1img", "http://localhost:5000/resources/static/assets/uploads/66/"+location.id+"/"+event.currentTarget.files[0].name);
                }} /> 
                <Thumb file={props.values.worker1img} />               
              </Form.Group>
              <TextInput
                name="worker2"
                label="Siivoojan sijainen"
                placeholder="Siivoojan sijainen"
                formik={ props }
              />
              <Form.Group>
                <Form.Label>Siivoojan sijainen (kuva)</Form.Label>
                <input id="worker2img" name="worker1img" type="file" onChange={(event) => {
                  handleUpload(66,location.id,event.currentTarget.files[0]);
                  props.setFieldValue("worker2imgtmp", event.currentTarget.files[0]);
                  props.setFieldValue("worker2img", "http://localhost:5000/resources/static/assets/uploads/66/"+location.id+"/"+event.currentTarget.files[0].name);
                }} />                
                <Thumb file={props.values.worker2img} />               
              </Form.Group>
              { error &&
                <Alert variant="danger" dismissible onClick={ (e) => setError(null) }>
                  <Alert.Heading>Virhe</Alert.Heading>
                  <p>{ error }</p>
                </Alert>
              }
              <Can I="read" a="Customer">
                <Form.Group>
                  <Form.Label>Kohteen asiakas</Form.Label>
                  <Form.Control
                    as="select"
                    name="customerId"
                    value={ props.values.customerId }
                    onChange={ props.handleChange }
                    onBlur={ props.handleBlur }
                  >
                      <option value="">Kaikki asiakkaat</option>
                      { customers && customers.map(customer => (
                        <Can I="read" this={customer}>
                          <option key={ customer.id } value={ customer.id }>
                            { customer.name }
                          </option>
                        </Can>)) }
                  </Form.Control>
                </Form.Group>
              </Can>
              <Can I="read" a="User">
                <Form.Group>
                  <Form.Label>Kohteen palveluasiantuntijat</Form.Label>
                  <Field name="managers">
                    {({ field }) => (
                      <Select
                        isMulti={ true }
                        value={ field.value }
                        onChange={ (value) => field.onChange({ target: { name: field.name, value }}) }
                        options={ managers && managers.map(({ id, name }) => ({
                          label: name,
                          value: id
                        })) }
                      />
                    )}
                  </Field>
                </Form.Group>
              </Can>
            <div className="align-right mt-4">
              <Button
                onClick={ () => {
                  onCancel();
                }}
                className="mr-3"
                type="button"
                variant="secondary"
              >
                Peruuta
              </Button>
              <Button
                type="submit"
              >
                Tallenna
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
