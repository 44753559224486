import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import ReactQuill from 'react-quill';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';
import Can from '../can.component';
import customerService from '../../services/customer.service';
import userService from '../../services/user.service';

import 'react-quill/dist/quill.snow.css';

const validationSchema = Yup.object({
  title: Yup.string()
    .required(errors.form.required),
  message: Yup.string()
});

export default function NewsForm({
  onSubmit,
  onCancel,
  isSendingData,
  item
}) {
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [customers, setCustomers] = useState();
  const [users, setUsers] = useState();

  useEffect(() => {
    async function getCustomers() {
      try {
        setIsFetchingCustomers(true);
        const response = await customerService.getAll();
        const data = response.data.map(customer => a('Customer', customer));
        setCustomers(data);
        setIsFetchingCustomers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingCustomers(false);
      }
    }
    getCustomers();

    async function getUsers() {
      try {
        setIsFetchingUsers(true);
        const response = await userService.getAll();
        const data = response.data.map(user => a('User', user));
        setUsers(data);
        setIsFetchingUsers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingUsers(false);
      }
    }
    getUsers();
  }, []);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  return (
    <>
      <AppLoader active={ isFetchingCustomers || isFetchingUsers || isSendingData } component={ true } />
      <Formik
        initialValues={{
          customerId: item ? item.customerId : '',
          userId: item ? item.userId : '',
          title: item ? item.title : '',
          titleimg: item ? item.titleimg : '',
          message: item ? item.message : ''
        }}
        validationSchema={ validationSchema}
        onSubmit={ onSubmit }
      >
        { props  => (
          <Form
            noValidate
            onSubmit={ props.handleSubmit }>
              <TextInput
                name="title"
                label="Otsikko"
                placeholder="Otsikko"
                formik={ props }
                required
              />
              <TextInput
                name="titleimg"
                label="Artikkelikuva"
                placeholder="Artikkelikuva(url)"
                formik={ props }
                required
              />
              <Form.Group>
                  <Form.Label>Viesti</Form.Label>
                  <Field name="message">
                    {({ field }) => <ReactQuill modules={modules} value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
              </Form.Group>
              <Can I="read" a="Customer">
                <Form.Group>
                  <Form.Label>Uutisen asiakas</Form.Label>
                  <Form.Control
                    as="select"
                    name="customerId"
                    value={ props.values.customerId }
                    onChange={ props.handleChange }
                    onBlur={ props.handleBlur }
                  >
                      <option value="">Lähetä kaikille</option>
                      { customers && customers.map(customer => (
                        <Can I="read" this={customer}>
                          <option key={ customer.id } value={ customer.id }>
                            { customer.name }
                          </option>
                        </Can>)) }
                  </Form.Control>
                </Form.Group>
              </Can>
              {/* <Can I="read" a="User">
                <Form.Group>
                  <Form.Label>Uutisen käyttäjä</Form.Label>
                  <Form.Control
                    as="select"
                    name="userId"
                    value={ props.values.userId }
                    onChange={ props.handleChange }
                    onBlur={ props.handleBlur }
                  >
                    <option value="">Valitse käyttäjä</option>
                    { users && users.map(user => (
                      <Can I="read" this={user}>
                        <option key={ user.id } value={ user.id }>
                          { user.username }
                        </option>
                      </Can>)) }
                </Form.Control>
              </Form.Group>
            </Can> */}
            <div className="align-right mt-4">
              <Button
                onClick={ () => {
                  onCancel();
                }}
                className="mr-3"
                type="button"
                variant="secondary"
              >
                Peruuta
              </Button>
              <Button
                type="submit"
              >
                Tallenna
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
