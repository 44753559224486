import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import MessageForm from './forms/messageForm.component';
import MessageItem from './messageItem.component';
import Modal from './modal.component';
import Page from './page.component';
import MessageDataService from '../services/message.service';

import './css/messages.style.css';

export default function Messages() {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingMessages, setIsFetchingMessages] = useState(true);

    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);

    const [showMessageModal, setShowMessageModal] = useState(false);
    const handleShowMessageModal = () => setShowMessageModal(true);
    const handleHideMessageModal = () => setShowMessageModal(false);
    const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);
    const handleShowCreateMessageModal = () => setShowCreateMessageModal(true);
    const handleHideCreateMessageModal = () => setShowCreateMessageModal(false);
    const [showEditMessageModal, setShowEditMessageModal] = useState(false);
    const handleShowEditMessageModal = () => setShowEditMessageModal(true);
    const handleHideEditMessageModal = () => setShowEditMessageModal(false);
    const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
    const handleShowDeleteMessageModal = () => setShowDeleteMessageModal(true);
    const handleHideDeleteMessageModal = () => setShowDeleteMessageModal(false);

    async function handleCreateMessage(values) {
      try {
        setIsSendingData(true);
        await MessageDataService.create(values);
        setIsSendingData(false);
        const response = await MessageDataService.getAll();
        const data = response.data.map(message => a('Message', message));
        setMessages(data);
        setShowCreateMessageModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleUpdateMessage(values) {
      try {
        setIsSendingData(true);
        await MessageDataService.update(selectedMessage.id, values);
        setIsSendingData(false);
        const response = await MessageDataService.getAll();
        const data = response.data.map(message => a('Message', message));
        setMessages(data);
        setShowEditMessageModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleDeleteMessage() {
      try {
        setIsSendingData(true);
        await MessageDataService.delete(selectedMessage.id);
        setSelectedMessage(null);
        setIsSendingData(false);
        const response = await MessageDataService.getAll();
        const data = response.data.map(message => a('Message', message));
        setMessages(data);
        setShowDeleteMessageModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleRefreshView() 
    {
      await getMessages();
      handleHideMessageModal();
    }

    async function getMessages() {
      try {
        setIsFetchingMessages(true);
        const response = await MessageDataService.getAll();
        const data = response.data.map(message => a('Message', message));
        setMessages(data);
        setIsFetchingMessages(false);
      } catch(error) {
        console.log(error);
        setIsFetchingMessages(false);
      }
    };

    useEffect(() => {
      getMessages();
    }, []);

    return (
        <Page center>
            <AppLoader active={ isFetchingMessages } component={ true } />

            <div
                className="vertical-center mt-5 mb-5"
            >
                <Can I="create" a="Message">
                    <span className="mb-0 button" onClick={ handleShowCreateMessageModal }>Kirjoita viesti
                    <ActionIcon
                        className="ml-5"
                        icon={ faPlusCircle }
                    />
                    </span>
                </Can>
            </div>
            <h2>Viestit</h2>
            <Can I="read" a="Message">
                <>
                    { messages && messages.map(message => (
                        <Can I="read" this={message}>
                            <MessageItem
                                key={ message.id }
                                message={ message }
                                onSelect={ setSelectedMessage }
                                onShow={ handleShowMessageModal }
                                onShowEdit={ handleShowEditMessageModal }
                                onShowDelete={ handleShowDeleteMessageModal } />
                        </Can>
                    )) }
                </>
            </Can>

            <Modal
                title="Viesti"
                size="lg"
                show={ showMessageModal }
                onHide={ handleRefreshView }
            >
                <MessageItem message={ selectedMessage } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleRefreshView }>
                        Sulje
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Lisää viesti"
                size="lg"
                show={ showCreateMessageModal }
                onHide={ handleHideCreateMessageModal }
            >
                <MessageForm
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateMessageModal }
                    onSubmit={ handleCreateMessage }
                />
            </Modal>
            <Modal
                title="Muokkaa viestiä"
                size="lg"
                show={ showEditMessageModal }
                onHide={ handleHideEditMessageModal }
            >
                <MessageForm
                    message={ selectedMessage }
                    isSendingData={ isSendingData }
                    onCancel={ handleHideEditMessageModal }
                    onSubmit={ handleUpdateMessage }
                />
            </Modal>
            <Modal
                title="Poista viesti"
                size="lg"
                show={ showDeleteMessageModal }
                onHide={ handleHideDeleteMessageModal }
            >
                <MessageItem message={ selectedMessage } />
                <p>Haluatko varmasti poistaa tämän viestin? Tätä toimintoa ei voi peruuttaa.</p>
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideDeleteMessageModal }>
                        Peruuta
                    </Button>
                    <Button className="ml-3" variant="danger" onClick={ handleDeleteMessage }>
                        Kyllä
                    </Button>
                </div>
            </Modal>
        </Page>
    );
}
