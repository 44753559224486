import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import MailTo from './mailTo.component';
import './css/customerItem.style.css';

export default function CustomerItem({ customer, onSelect, onShow, onShowEdit, onShowDelete }) {
    function handleShow() {
        if (onSelect) onSelect(customer);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(customer);
        onShowEdit();
    }

    function handleShowDelete() {
        onSelect(customer);
        onShowDelete();
    }

    function isUpdated() {
        return moment(customer.updatedAt).isAfter(moment(customer.createdAt));
    }

    return (
        <div className="customer-item">
            <div className="customer-item-content-wrapper" onClick={ handleShow }>
                <div className="customer-item-content">
                    <h6>{ customer.name }</h6>
                    <MailTo
                        email={ customer.email }
                        onClick={ (e) => e.stopPropagation(true) }>
                        { customer.email }
                    </MailTo>
                    <p>{ customer.address }</p>
                    <p>{ customer.phone }</p>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            <p className="mt-2"><small>Luotu: { moment(customer.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: { moment(customer.updatedAt).format('lll') }</small></p> }
                        </> }
                </div>
            </div>
            <div className="customer-item-actions">
              { onSelect && onShowEdit && <Can I="update" this={customer}>
                  <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                      <FontAwesomeIcon icon={faPen} />
                  </Button>
              </Can> }
              { onSelect && onShowDelete && <Can I="delete" this={customer}>
                  <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                      <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
              </Can> }
            </div>
        </div>
    );
}
