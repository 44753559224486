import authService from './services/auth.service';
import { get } from 'lodash';

/**
 * Returns whether the user has a permitted role or not.
 *
 * @param {array} permittedRoles The roles that permit the user.
 * @return {boolean} Whether the user has a permitted role or not.
 */
export function hasPermittedRole(permittedRoles = []) {
    const user = authService.getCurrentUser();
    const roles = user ? user.roles ? user.roles : [] : [];

    return user ?
      permittedRoles.length > 0 ?
          permittedRoles.some(role => roles.includes(role)) :
        true :
      false;
}

/**
 * Returns whether the user has the required roles or not.
 *
 * @param {array} requiredRoles The roles the user is required to have.
 * @return {boolean} Whether the user has the required roles or not.
 */
export function hasRequiredRoles(requiredRoles = []) {
    const user = authService.getCurrentUser();
    const roles = user ? user.roles ? user.roles : [] : [];

    return user ?
      requiredRoles.every(role => roles.includes(role)) :
      false;
}

export function resolveRolesToId(roles) {
  const roleIds = roles.map(role => get(role, 'user_roles.roleId'));
  return roleIds[0];
}
export function resolveWorklocationsToId(locations) {
  return locations.map(role => get(role, 'user_locations.locationId'));
}
