import React, { Component, useState, useEffect } from "react";
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import CustomerDataService from "../services/customer.service";
import Page from "./page.component";
import ActionIcon from "./actionIcon.component";
import Can from "./can.component";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "./modal.component";
import CustomerForm from "./forms/customerForm.component";
import customerService from "../services/customer.service";
import CustomerItem from "./customerItem.component";
import './css/customers.style.css';
import AppLoader from "./appLoader.component";

export default function Customers() {
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const handleShowCustomerModal = () => setShowCustomerModal(true);
  const handleHideCustomerModal = () => setShowCustomerModal(false);
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const handleShowCreateCustomerModal = () => setShowCreateCustomerModal(true);
  const handleHideCreateCustomerModal = () => setShowCreateCustomerModal(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const handleShowEditCustomerModal = () => setShowEditCustomerModal(true);
  const handleHideEditCustomerModal = () => setShowEditCustomerModal(false);
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
  const handleShowDeleteCustomerModal = () => setShowDeleteCustomerModal(true);
  const handleHideDeleteCustomerModal = () => setShowDeleteCustomerModal(false);

  async function handleCreateCustomer(values) {
    try {
      setIsSendingData(true);
      await CustomerDataService.create(values);
      setIsSendingData(false);
      const response = await CustomerDataService.getAll();
      const data = response.data.map(customer => a('Customer', customer));
      setCustomers(data);
      setShowCreateCustomerModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateCustomer(values) {
    try {
      setIsSendingData(true);
      await CustomerDataService.update(selectedCustomer.id, values);
      setIsSendingData(false);
      const response = await CustomerDataService.getAll();
      const data = response.data.map(customer => a('Customer', customer));
      setCustomers(data);
      setShowEditCustomerModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteCustomer() {
    try {
      setIsSendingData(true);
      await CustomerDataService.delete(selectedCustomer.id);
      setSelectedCustomer(null);
      setIsSendingData(false);
      const response = await CustomerDataService.getAll();
      const data = response.data.map(customer => a('Customer', customer));
      setCustomers(data);
      setShowDeleteCustomerModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  useEffect(() => {
    async function getCustomers() {
      try {
        setIsFetchingCustomers(true);
        const response = await customerService.getAll();
        const data = response.data.map(customer => a('Customer', customer));
        setCustomers(data);
        setIsFetchingCustomers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingCustomers(false);
      }
    }
    getCustomers();
  }, []);

  return (
    <Page center>
      <AppLoader
        active={ isFetchingCustomers }
        component={ true }
      />
      <Can I="create" a="Customer">
        <div className="mt-5 mb-5 ml-5">
          <span className="mb-0 button" onClick={ handleShowCreateCustomerModal }>Luo uusi asiakas
          <ActionIcon
            className="ml-5"
            icon={ faPlusCircle }
          /></span>
        </div>
      </Can>
      <h2>Asiakkaat</h2>
      <Can I="read" a="Customer">
        <>
          { customers && customers.map(customer => (
            <Can I="read" this={customer}>
              <CustomerItem
                key={customer.id}
                customer={ customer }
                onSelect={ setSelectedCustomer }
                onShow={ handleShowCustomerModal }
                onShowEdit={ handleShowEditCustomerModal }
                onShowDelete={ handleShowDeleteCustomerModal } />
            </Can>
          )) }
        </>
      </Can>

      <Modal
        title="Asiakas"
        show={ showCustomerModal }
        onHide={ handleHideCustomerModal }
      >
        <CustomerItem customer={ selectedCustomer } />
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideCustomerModal }>
            Sulje
          </Button>
        </div>
      </Modal>
      <Modal
        title="Lisää asiakas"
        show={ showCreateCustomerModal }
        onHide={ handleHideCreateCustomerModal }
      >
        <CustomerForm
          isSendingData={ isSendingData }
          onCancel={ handleHideCreateCustomerModal }
          onSubmit={ handleCreateCustomer }
        />
      </Modal>
      <Modal
        title="Muokkaa asiakasta"
        show={ showEditCustomerModal }
        onHide={ handleHideEditCustomerModal }
      >
        <CustomerForm
          customer={ selectedCustomer }
          isSendingData={ isSendingData }
          onCancel={ handleHideEditCustomerModal }
          onSubmit={ handleUpdateCustomer }
        />
      </Modal>
      <Modal
        title="Poista asiakas"
        show={ showDeleteCustomerModal }
        onHide={ handleHideDeleteCustomerModal }
      >
        <CustomerItem customer={ selectedCustomer } />
        <p>Haluatko varmasti poistaa tämän asiakkaan? Tätä toimintoa ei voi peruuttaa.</p>
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideDeleteCustomerModal }>
            Peruuta
          </Button>
          <Button className="ml-3" variant="danger" onClick={ handleDeleteCustomer }>
            Kyllä
          </Button>
        </div>
      </Modal>
  </Page>
  );
}
