import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ActionIcon(props) {
    return (
        <FontAwesomeIcon
            { ...props }
            style={{
                color: '#62CA3C',
                fontSize: '32px'
            }}
        />
    );
}