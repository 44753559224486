import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import './css/locationItem.style.css';

export default function LocationItem({ location, onSelect, onShow, onShowEdit, onShowDelete }) {
    function handleShow() {
        if (onSelect) onSelect(location);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(location);
        onShowEdit();
    }

    function handleShowDelete() {
        onSelect(location);
        onShowDelete();
    }

    function isUpdated() {
        return moment(location.updatedAt).isAfter(moment(location.createdAt));
    }

    if(!location){
        return null;
    } else {
    return (
        <div key={location.name} className="location-item">
            <div className="location-item-content-wrapper" onClick={ handleShow }>
                <div className="location-item-content">
                    <h6>{ location.name }</h6>
                    <p>{ location.address }</p>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            {location.managers &&
                                <>
                                <p><b>Palveluasiantuntija</b></p>
                                <p>{location.managers[0].name}</p>
                                <p>{location.managers[0].email}</p>
                                <p>puh: 040 583 7766</p>
                                <img src={location.managers[0].profileImage} width="120"></img>
                                </>
                            }
                            {location.worker1 &&
                                <>
                                <p><b>Kohteen siivooja</b></p>
                                <p>{location.worker1}</p>
                                <img src={location.worker1img} width="120"></img>
                                </>
                            }
                            {location.worker2 &&
                                <>
                                <p>Siivoojan sijainen</p>
                                <img src={location.worker2img} width="120"></img>
                                </>
                            }
                            <p><b>Esittely/huomioitavaa</b></p>
                            <p dangerouslySetInnerHTML={{ __html: location.description }}></p>
                            <p><b>Työvälineet</b></p>
                            <p dangerouslySetInnerHTML={{ __html: location.tools }}></p>
                            <p><b>Työtavat</b></p>
                            <p dangerouslySetInnerHTML={{ __html: location.ways }}></p>
                            <p className="mt-2"><small>Luotu: { moment(location.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: { moment(location.updatedAt).format('lll') }</small></p> }
                        </> }
                </div>
            </div>
            <div className="location-item-actions">
                { onSelect && onShowEdit && <Can I="update" this={location}>
                    <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </Can> }
                { onSelect && onShowDelete && <Can I="delete" this={location}>
                    <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Can> }
            </div>
        </div>
    );
}
}
