import React, { Component } from "react";
import { subject as a } from "@casl/ability";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { faCamera, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ActionIcon from "./actionIcon.component";
import Can from './can.component';
import Page from "./page.component";
import AuthService from "../services/auth.service";
import LocationDataService from "../services/location.service";

import './css/locations-list.style.css';
import illustration from "../assets/images/kuvitus_siivoojat.png";
import UploadFiles from "./upload-files.component";

export default class LocationsListINUSE extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchName = this.onChangeSearchName.bind(this);
    this.retrieveLocations = this.retrieveLocations.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveLocation = this.setActiveLocation.bind(this);
    this.removeAllLocations = this.removeAllLocations.bind(this);
    this.searchName = this.searchName.bind(this);

    this.state = {
      locations: [],
      currentLocation: null,
      searchName: "",
      redirect: null,
      userReady: false,
      currentUser: { username: "" }
    };
  }

  componentDidMount() {
    this.retrieveLocations();
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });
  }

  onChangeSearchName(e) {
    const searchName = e.target.value;

    this.setState({
      searchName: searchName
    });
  }

  retrieveLocations() {
    LocationDataService.getAll()
      .then(response => response.data.map(location => a('Location', location)))
      .then(data => {
        this.setState({
          locations: data
        });
        console.log(data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveLocations();
    this.setState({
      currentLocation: null
    });
  }

  setActiveLocation(location) {
    this.setState({
      currentLocation: location
    });
  }

  removeAllLocations() {
    LocationDataService.deleteAll()
      .then(response => {
        console.log(response.data);
        this.refreshList();
      })
      .catch(e => {
        console.log(e);
      });
  }

  searchName() {
    LocationDataService.findByName(this.state.searchName)
      .then(response => {
        this.setState({
          locations: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  onChangeLocation(e) {
    const locationId = parseInt(e.target.value, 10);
    const location = this.state.locations.find((location) => location.id === locationId);
    this.setActiveLocation(location);
  }

  render() {
    const { searchName, locations, currentLocation, currentUser } = this.state;

    return (
      <Page className="locations-list-content">
        <div className="locations-list-layout">
          <div className="text">
            <Can I="read" a="Location">
              <h2 className="mb-5">Kohteet</h2>
              <Form>
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="location"
                    id="location"
                    onChange={(e) => this.onChangeLocation(e)}
                  >
                    <option value="">Valitse kohde</option>
                    {
                      locations && locations.map((location) => (
                        <Can I="read" this={location}>
                          <option key={location.id} value={location.id}>
                            {location.name}
                          </option>
                        </Can>
                      ))
                    }
                  </Form.Control>
                </Form.Group>
              </Form>
              { currentLocation &&
                <div className="mt-5">
                  <h6>{ currentLocation.name }</h6>
                  <p>{ currentLocation.address }</p>
                </div>
              }
            </Can>
            <h6 className="mt-5 mb-2">Työtilaus</h6>
            <h6 className="mt-2 mb-2">Sopimus</h6>
            <h6 className="mt-2 mb-2">Pohjakuva</h6>
            <h6 className="mt-2 mb-2">Työohjeet</h6>
            <h6 className="mt-2 mb-2">Siivousvälineet</h6>
            <h6 className="mt-2 mb-2">Siivousaineet</h6>
            <h6 className="mt-2 mb-2">Muut tärkeät työtavat</h6>
            <h6 className="mt-2 mb-2">Laadunvalvontakuvat</h6>
            <div className="vertical-center">
              <h6 className="mt-4 mb-4">Lisää tiedosto</h6><ActionIcon className="ml-3" icon={ faPlusCircle} />
              <UploadFiles />
            </div>
          </div>
          <div className="illustration">
            <img src={ illustration } alt="" />
          </div>
        </div>
      </Page>
    );
  }
}
