import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../services/auth.service';
import { hasPermittedRole, hasRequiredRoles } from '../utils';

export default function PrivateRoute({
    permittedRoles,
    requiredRoles,
    children,
    ...rest
}) {
    const user = authService.getCurrentUser();
    const hasAccess = requiredRoles ?
      hasRequiredRoles(requiredRoles) :
        permittedRoles ?
        hasPermittedRole(permittedRoles) :
      true;

    return (
        <Route { ...rest } render={ ({ location }) => {
            return user ?
                hasAccess ?
                    (
                        children
                    ) : (
                        <Redirect
                            to="unauthorized"
                        />
                    ) :
                (
                    <Redirect
                        to="login"
                    />
                );
            }}
        />
    );
};
