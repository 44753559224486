import React from 'react';
import logo from '../assets/images/logo-mtb.png';

import './css/page.style.css';

export default function Page({
    children,
    center = false,
    className
}) {
    return (
        <div className={ `page-wrapper ${ className ? className : '' }` } >
            <div className="page-header">
                <img src={ logo } alt="" className="mtb-logo" />
            </div>
            <div className={ `page-content ${ center ? 'center' : '' }` }>
                { children }
            </div>
        </div>
    );
}