import React, { useState } from "react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ActionIcon from "./actionIcon.component";
import Page from "./page.component";
import UploadFiles from "./upload-files.component";
import Modal from './modal.component';

import './css/cleaningInfo.style.css';
import illustration from "../assets/images/kuvitus_siivoojat.png";

export default function CleaningInfo() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleHideUploadModal = () => setShowUploadModal(false);

  return (
    <Page className="cleaningInfo-content">
      <div className="cleaningInfo-layout">
        <div className="text">
          <h2 className="mb-5">Siivousmetodit</h2>
          {/* <h6 className="mt-5 mb-2"><a href="/cleaning-info/1">Työtavat</a></h6> */}
          {/* <h6 className="mt-2 mb-2"><a href="/cleaning-info/2">Työohjeet</a></h6> */}
          <h6 className="mt-2 mb-2"><a href="/cleaning-info/3">Siivousaineet</a></h6>
          {/* <h6 className="mt-2 mb-2"><a href="/cleaning-info/4">Siivousvälineet</a></h6> */}
          <div className="vertical-center">
            <span className="mb-4 mt-4 button" onClick={handleShowUploadModal} >Lisää tiedosto<ActionIcon className="ml-3" icon={ faPlusCircle}/></span>
          </div>
        </div>
        <div className="illustration">
          <img src={ illustration } alt="" />
        </div>
      </div>
      <Modal
        title="Lisää tiedosto/kuva"
        show={ showUploadModal }
        onHide={ handleHideUploadModal }
    >
      <UploadFiles
      onCancel={ handleHideUploadModal } />
    </Modal>
    </Page>
  );
}
