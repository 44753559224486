import React from 'react';

export default function MailTo({ email, subject = '', body = '', children, ...rest }) {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${ encodeURIComponent(subject) }`;
    if (body) params += `${ subject ? '&' : '' }body=${ encodeURIComponent(body) }`;

    return (
        <a
            href={ `mailto:${ email }${ params }` }
            { ...rest }>
            { children }
        </a>
    );
}
