import { AbilityBuilder, Ability, detectSubjectType } from '@casl/ability';

import authService from '../services/auth.service';
import { UserRole } from '../enums';

const user = authService.getCurrentUser();

export const AppAbility = Ability;

export default function defineRulesFor(role) {
    const { can, rules } = new AbilityBuilder();
    const roles = user ? user.roles ? user.roles : [] : [];
    if(user)
        console.log(user.locations);
    for (let i = 0; i < roles.length; i++) {
        if (user.roles[i] === UserRole.ADMIN) {
            can('manage', 'Customer');
            can('manage', 'Location');
            can('manage', 'Method');
            can('manage', 'News');
            can('manage', 'Quality');
            can('manage', 'User');
            can('manage', 'Message');
            can('manage', 'Workorder');
            can('manage', 'Tool');
            can('manage', 'Chemical');
            can('manage', 'Workmanual');
            can('manage', 'Workingway');
        } else if (user.roles[i] === UserRole.SUPERVISOR) {
            can('manage', 'Location', { id: { $in: user.locations } });
            can('read', 'Material');
            can('create', 'Message');
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
            can('read', 'Quality');
            can('read', 'Workorder');
            can('read', 'Tool');
        } else if (user.roles[i] === UserRole.SALES) {
            can('manage', 'Location', { id: { $in: user.locations } });
            can('create', 'Location');
            can('manage', 'Customer');
            can('read', 'Material');
            can('create', 'Message');
            can('reply', 'Message', { locationId: { $in: user.locations } });
            can('update', 'Message', { userId: user.id } );
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
            can('read', 'Quality');
            can('read', 'Workorder');
            can('read', 'Tool');
        } else if (user.roles[i] === UserRole.CUSTOMER_ADMIN) {
            can('read', 'Location', { id: { $in: user.locations } });
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('create', 'User');
            can(['read', 'update'], 'User', ['username', 'email', 'password'], { customerId: user.customerId });
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
        } else if (user.roles[i] === UserRole.CUSTOMER) {
            can('read', 'Location', { id: { $in: user.locations } });
            can('create', 'Message');
            can('reply', 'Message', { locationId: { $in: user.locations } });
            can('update', 'Message', { userId: user.id } );
            can('read', 'Message', { locationId: { $in: user.locations } });
            can('read', 'Workorder', { locationId: { $in: user.locations } });
            can('read', 'Message', { userId: user.id });
            can('read', 'News', { customerId: user.customerId });
            can('read', 'News', { customerId: 0 });
            can('read', 'News', { userId: user.id });
            can('read', 'Quality');
            can('read', 'Workorder', { assignee: 'me' });
            can(['read','create','update'], 'Workorder', { id: { $in: user.locations } });
            can('read', 'Tool');
            can('read', 'Chemical');
            can('read', 'Workmanual');
            can('read', 'Workingway');
        }
    }
    return rules;
}

/**
 * Read for details: https://stalniy.github.io/casl/v4/en/guide/subject-type-detection
 */
function detectAppSubjectType(subject) {
    if (subject && typeof subject === 'object' && subject.type) {
        return subject.type;
    }
    return detectSubjectType(subject);
}

export function buildAbilityFor(role) {
    return new AppAbility(defineRulesFor(role), {
        detectSubjectType: detectAppSubjectType
    });
}
