import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import './css/locationChemicals.style.css';

export default function LocationChemicals({ chemicals, selChemicals = []}) {
  const [selectedChemicals, setSelectedChemicals] = useState([]);

  function handleToggleSelectChemical(chemical) {
    if (selectedChemicals.some((selectedChemical) => selectedChemical.name === chemical.name)) {
      setSelectedChemicals(selectedChemicals.filter((selectedChemical) => selectedChemical.name !== chemical.name));
    } else {
      setSelectedChemicals([...selectedChemicals, chemical]);
    }
  }

  function isSelectedChemical(chemical) {
    let isSelected = false;
    isSelected = selectedChemicals.some((selectedChemical) => selectedChemical.name === chemical.name);
    // if (isSelected){ 
    //   if (selectedChemicals.some((selectedChemical) => selectedChemical.name === chemical.name)) {
    //     setSelectedChemicals(selectedChemicals.filter((selectedChemical) => selectedChemical.name !== chemical.name));
    //   } else {
    //     setSelectedChemicals([...selectedChemicals, chemical]);
    //   }
    // }
    return isSelected;
  }

  useEffect(() => {
    setSelectedChemicals(selChemicals);
  }, []);
  
  return (
    <>
      <ListGroup>
        { chemicals && chemicals.map((chemical, index) => (
          <ListGroup.Item
            key={ index }
            onClick={ () => handleToggleSelectChemical(chemical) }>
            <div className="list-group-item-content">
              <a href={ chemical.url } target="_blank" rel="noopener noreferrer">
                 {chemical.name}
              </a>
              {
                <div className={ `list-group-item-checkbox ${ isSelectedChemical(chemical) && 'active' }` }>
                  {
                    isSelectedChemical(chemical) ?
                      <FontAwesomeIcon icon={faCheckSquare} /> :
                      <FontAwesomeIcon icon={faSquare} />
                  }
                </div>
              }
            </div>
          </ListGroup.Item>
        )) }
      </ListGroup>
    </>
  );
}
