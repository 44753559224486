import http from "../http-common";

const user = JSON.parse(window.localStorage.getItem('user'));
const userId = user ? user.id : null;

class WorkorderDataService {
  getAll() {
    return http.get(`/workorders`);
  }

  get(id) {
    return http.get(`/workorders/${id}`);
  }

  create(data) {
    return http.post(`/workorders`, { ...data, userId });
  }

  update(id, data) {
    return http.put(`/workorders/${id}`, data);
  }

  delete(id) {
    return http.delete(`/workorders/${id}`);
  }

  deleteAll() {
    return http.delete(`/workorders`);
  }

  findByName(name) {
    return http.get(`/workorders?name=${name}`);
  }
}

export default new WorkorderDataService();
