import React from 'react';
import { Form } from 'react-bootstrap';

export function TextInput({
    name,
    label,
    placeholder,
    formik,
    required,
    ...rest
}) {
    return (
        <Form.Group>
            { label &&
                <Form.Label>{ label }{ required && ' *' }</Form.Label>
            }
            <Form.Control
                id={ name }
                name={ name }
                placeholder={ placeholder}
                value={ formik.values[name] }
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                isInvalid={ formik.touched[name] && formik.errors[name] }
                { ...rest }
            />
            { formik.touched[name] && formik.errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors[name] }
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    )
}