import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import MailTo from './mailTo.component';
import './css/userItem.style.css';

export default function UserItem({ user, onSelect, onShow, onShowEdit, onShowDelete, onShowLocations }) {
    function handleShow() {
        if (onSelect) onSelect(user);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(user);
        onShowEdit();
    }

    function handleShowDelete() {
        onSelect(user);
        onShowDelete();
    }

    function handleShowLocations() {
        onSelect(user);
        onShowLocations();
    }

    function isUpdated() {
        return moment(user.updatedAt).isAfter(moment(user.createdAt));
    }

    if(!user){
        return null;
    } else {
    return (
        <div className="user-item">
            <div className="user-item-content-wrapper" onClick={ handleShow }>
                <div className="user-from-avatar"></div>
                <div className="user-item-content">
                    <p>{ user.username }</p>
                    <MailTo
                        email={ user.email }
                        onClick={ (e) => e.stopPropagation(true) }>
                        { user.email }
                    </MailTo>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            <p className="mt-2"><small>Luotu: { moment(user.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: { moment(user.updatedAt).format('lll') }</small></p> }
                        </> }
                </div>
            </div>
            <div className="user-item-actions">
              { onSelect && onShowEdit && <Can I="update" this={user}>
                  <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                      <FontAwesomeIcon icon={faPen} />
                  </Button>
              </Can> }
              { onSelect && onShowLocations && <Can I="update" this={user}>
                  <Button variant="secondary" size="sm" onClick={ handleShowLocations }>
                      <FontAwesomeIcon icon={ faMapMarkerAlt} />
                  </Button>
              </Can> }
              { onSelect && onShowDelete && <Can I="delete" this={user}>
                  <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                      <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
              </Can> }
            </div>
        </div>
    );
}
}
