import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import './css/uploadedFiles.style.css';

export default function UploadedFiles({ files, readonly = false, asImages = false }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  function handleToggleSelectFile(file) {
    if (selectedFiles.some((selectedFile) => selectedFile.name === file.name)) {
      setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.name !== file.name));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  }

  function isSelectedFile(file) {
    return selectedFiles.some((selectedFile) => selectedFile.name === file.name);
  }

  return (
    <>
      <ListGroup className="listItemContainer">
        { files && files.map((file, index) => (
          <ListGroup.Item
            className={`listItem ${asImages ? 'image': 'text'}`}
            key={ index }
            onClick={ () => handleToggleSelectFile(file) }>
              <a href={ file.url } target="_blank" rel="noopener noreferrer">
                {!asImages &&
                 file.name 
                }
                <img src={asImages &&
                 file.url
                } width="120"/>
              </a>
              {
                !readonly &&
                <div className={ `list-group-item-checkbox ${ isSelectedFile(file) && 'active' }` }>
                  {
                    isSelectedFile(file) ?
                      <FontAwesomeIcon icon={faCheckSquare} /> :
                      <FontAwesomeIcon icon={faSquare} />
                  }
                </div>
              }
          </ListGroup.Item>
        )) }  
        {files.length == 0 &&
          <ListGroup.Item
            className={`listItem text`}
            key="1">
            <p>ei näytettävää sisältöä</p>
          </ListGroup.Item>
        }
      </ListGroup>
    </>
  );
}
