import React, { useEffect, useMemo, useState } from 'react';
import { subject as a } from '@casl/ability';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';
import Can from '../can.component';
import api from '../../http-common';
import customerService from '../../services/customer.service';
import locationService from '../../services/location.service';
import Select from 'react-select'
import { resolveRolesToId } from '../../utils';
import UploadService from "../../services/upload-files.service";

export default function UserForm({
  onSubmit,
  onCancel,
  user
}) {
  const [isSendingData, setIsSendingData] = useState(false);
  const [error, setError] = useState(null);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState();
  const me = JSON.parse(window.localStorage.getItem('user'));

  const validationSchema = useMemo(() => Yup.object({
    username: Yup.string()
      .required(errors.form.required),
    name: Yup.string()
      .required(errors.form.required),
    email: Yup.string().email(errors.form.invalidEmail)
      .required(errors.form.required),
    password: user ?
      Yup.string()
        .nullable() :
      Yup.string()
        .required(errors.form.required),
    confirmPassword: user ?
      Yup.string()
        .oneOf([Yup.ref('password'), null], errors.form.invalidConfirmPassword)
        .nullable() :
      Yup.string()
        .oneOf([Yup.ref('password'), null], errors.form.invalidConfirmPassword)
        .required(errors.form.required)
  }), [user]);

  useEffect(() => {
    async function getRoles() {
      try {
        setIsFetchingRoles(true);
        const response = await api.get('/roles');
        const { data } = response;
        setRoles(data);
        setIsFetchingRoles(false);
      } catch(error) {
        console.log(error);
        setIsFetchingRoles(false);
      }
    }
    getRoles();

    async function getCustomers() {
      try {
        setIsFetchingCustomers(true);
        const response = await customerService.getAll();
        const data = response.data.map(customer => a('Customer', customer));
        setCustomers(data);
        setIsFetchingCustomers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingCustomers(false);
      }
    }
    getCustomers();
  }, []);

  if (user && roles) {
    const roleId = resolveRolesToId(user.roles);
    console.log('[userForm] roleId', roleId);
    const role = roles.find(role => role.id === roleId);
    user = {
      ...user,
      role: role ? role.name : ''
    };
  }

  class Thumb extends React.Component {
    state = {
      loading: false,
      thumb: undefined,
    };
  
    componentWillReceiveProps(nextProps) {
      if (!nextProps.file) { return; }
  
      this.setState({ loading: true }, () => {
        this.setState({ loading: false, thumb: nextProps.file });
      });
    }
  
    render() {
      const { file } = this.props;
      const { loading, thumb } = this.state;
  
      if (!file) { return null; }
  
      if (loading) { return <p>loading...</p>; }
  
      return (<img src={file}
        alt={file.name}
        className="img-thumbnail mt-2"
        height={100}
        width={100} />);
    }
  }

  async function handleUpload(categoryId, locationId, file) {
    const payload = { categoryId, locationId, file: file };
    try {
      console.log(payload);
      setIsSendingData(true);
      await UploadService.upload(payload);
    } catch (error) {
      console.log(error);
      setError('Kuvan lisääminen ei onnistunut.');
    } finally {
      setIsSendingData(false);
    }
  }

  
    return (
    <>
      <AppLoader
        active={ isFetchingCustomers || isFetchingLocations || isFetchingRoles || isSendingData }
        component={ true }
      />
      { roles &&
        <Formik
          initialValues={{
            username: user ? user.username : '',
            name: user ? user.name : '',
            profileImage: user ? user.profileImage : '',
            email: user ? user.email : '',
            role: user ? user.role : '',
            customerId: user ? user.customerId : me ? me.customerId : '',
            password: user ? null : '',
            confirmPassword: user ? null : ''
          }}
          validationSchema={ validationSchema }
          onSubmit={ onSubmit }
        >
          { props  => (
            <Form
              noValidate
              onSubmit={ props.handleSubmit }>
                <TextInput
                  name="username"
                  label="Käyttäjänimi"
                  placeholder="Käyttäjänimi"
                  formik={ props }
                  required
                />
                <TextInput
                  name="name"
                  label="Nimi"
                  placeholder="Nimi"
                  formik={ props }
                  required
                />
                <TextInput
                  type="email"
                  name="email"
                  label="Sähköpostiosoite"
                  placeholder="Sähköpostiosoite"
                  formik={ props }
                  required
                />
                <TextInput
                  type="phone"
                  name="phone"
                  label="Puhelin"
                  placeholder="Puhelin"
                  formik={ props }
                />
                <label for="file">Käyttäjän kuva</label>
                <input id="file" name="file" type="file" onChange={(event) => {
                  handleUpload(66,'',event.currentTarget.files[0]);
                  props.setFieldValue("profileImage", "http://localhost:5000/resources/static/assets/uploads/66/"+event.currentTarget.files[0].name);
                }} />
                <Thumb file={props.values.profileImage} />                               
                <Can I="read" a="User" field="roles">
                  <Form.Group>
                    <Form.Label>Käyttäjän rooli</Form.Label>
                    <Form.Control
                      as="select"
                      name="role"
                      value={ props.values.role }
                      onChange={ props.handleChange }
                      onBlur={ props.handleBlur }
                    >
                        <option value="">Valitse rooli</option>
                        { roles.map(role => (
                          <option key={ role.id } value={ role.name }>
                            { role.show }
                          </option>)) }
                    </Form.Control>
                  </Form.Group>
                </Can>
                <Can I="read" a="User" field="customerId">
                  <Form.Group>
                    <Form.Label>Käyttäjän asiakas</Form.Label>
                    <Form.Control
                      as="select"
                      name="customerId"
                      value={ props.values.customerId }
                      onChange={ props.handleChange }
                      onBlur={ props.handleBlur }
                    >
                        <option value="">Valitse asiakas</option>
                        { customers && customers.map(customer => (
                          <Can I="read" this={customer}>
                            <option key={ customer.id } value={ customer.id }>
                              { customer.name }
                            </option>
                          </Can>)) }
                    </Form.Control>
                  </Form.Group>
                </Can>
                <TextInput
                  type="password"
                  name="password"
                  label="Salasana"
                  placeholder="Salasana"
                  formik={ props }
                  required={ !user }
                />
                <TextInput
                  type="password"
                  name="confirmPassword"
                  label="Salasana uudelleen"
                  placeholder="Salasana uudelleen"
                  formik={ props }
                  required={ !user }
                />
              <div className="align-right mt-4">
                <Button
                  onClick={ () => {
                    onCancel();
                  }}
                  className="mr-3"
                  type="button"
                  variant="secondary"
                >
                  Peruuta
                </Button>
                <Button
                  type="submit"
                  disabled={ user && !props.dirty }
                >
                  Tallenna
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  );
}
