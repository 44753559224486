import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { faCamera, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import LocationItem from './locationItem.component';
import Modal from './modal.component';
import Page from './page.component';
import UploadFiles from './upload-files.component';
import UploadedFiles from "./uploadedFiles.component";
import LocationDataService from '../services/location.service';
import UploadService from "../services/upload-files.service";
import QualityForm from './forms/qualityForm.component';
import QualityItem from './qualityItem.component';
import QualityDataService from '../services/quality.service';

import illustration from '../assets/images/kuvitus_siivoojat.png';
import './css/locations-list.style.css';

export default function QualityControl() {
  const [isFetchingLocations, setIsFetchingLocations] = useState(true);

  const [fileInfos, setFileInfos] = useState([]);
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const handleShowLocationModal = () => setShowLocationModal(true);
  const handleHideLocationModal = () => setShowLocationModal(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleHideUploadModal = () => setShowUploadModal(false);
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingQualities, setIsFetchingQualities] = useState(true);

  const [qualities, setQualities] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(null);

  const [showQualityModal, setShowQualityModal] = useState(false);
  const handleShowQualityModal = () => setShowQualityModal(true);
  const handleHideQualityModal = () => setShowQualityModal(false);
  const [showCreateQualityModal, setShowCreateQualityModal] = useState(false);
  const handleShowCreateQualityModal = () => setShowCreateQualityModal(true);
  const handleHideCreateQualityModal = () => setShowCreateQualityModal(false);
  const [showEditQualityModal, setShowEditQualityModal] = useState(false);
  const handleShowEditQualityModal = () => setShowEditQualityModal(true);
  const handleHideEditQualityModal = () => setShowEditQualityModal(false);
  const [showDeleteQualityModal, setShowDeleteQualityModal] = useState(false);
  const handleShowDeleteQualityModal = () => setShowDeleteQualityModal(true);
  const handleHideDeleteQualityModal = () => setShowDeleteQualityModal(false);

  async function handleCreateQuality(values) {
    try {
      setIsSendingData(true);
      await QualityDataService.create(values);
      setIsSendingData(false);
      const response = await QualityDataService.getAll();
      const data = response.data.map(quality => a('Quality', quality));
      setQualities(data);
      setShowCreateQualityModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateQuality(values) {
    try {
      setIsSendingData(true);
      await QualityDataService.update(selectedQuality.id, values);
      setIsSendingData(false);
      const response = await QualityDataService.getAll();
      const data = response.data.map(quality => a('Quality', quality));
      setQualities(data);
      setShowEditQualityModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteQuality() {
    try {
      setIsSendingData(true);
      await QualityDataService.delete(selectedQuality.id);
      setSelectedQuality(null);
      setIsSendingData(false);
      const response = await QualityDataService.getAll();
      const data = response.data.map(quality => a('Quality', quality));
      setQualities(data);
      setShowDeleteQualityModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleRefreshView() 
  {
    await getQualities();
    handleHideQualityModal();
  }

  async function getQualities(location) {
    try {
      setIsFetchingQualities(true);
      const response = await QualityDataService.getAllByLoc(location.id);
//      const response = await QualityDataService.getAll();
      const data = response.data.map(quality => a('Quality', quality));
      setQualities(data);
      setIsFetchingQualities(false);
    } catch(error) {
      console.log(error);
      setIsFetchingQualities(false);
    }
  };

  useEffect(() => {
    getQualities(selectedLocation);
  }, [selectedLocation]);


  function handleChangeLocation(locationId) {
    const location = locationId ? locations.find(location => location.id === parseInt(locationId, 10)) : null;
    setSelectedLocation(location);
    getFiles(location);
  }

  async function getFiles(location) {
    try {
      setIsFetchingFiles(true);
      if(location){
        const response = await UploadService.getFiles(8, location.id);
        setFileInfos(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingFiles(false);
    }
  }

useEffect(() => {
  async function getLocations() {
      try {
        setIsFetchingLocations(true);
        const response = await LocationDataService.getAll();
        const data = response.data.map(location => a('Location', location));
        setLocations(data);
        setIsFetchingLocations(false);
      } catch(error) {
        console.log(error);
        setIsFetchingLocations(false);
      }
    }
    getLocations();
  }, []);

  useEffect(() => {
    if (locations && locations[0]) {
      handleChangeLocation(locations[0].id);
    }
    console.log(locations, '- Has changed')
  },[locations]) // <-- here put the parameter to listen

  return (
    <Page className="locations-list-content">
      <AppLoader active={ isFetchingFiles || isFetchingLocations ||isFetchingQualities } component={ true } />
      <div
                className="vertical-center mt-5 mb-5"
            >
                <Can I="create" a="Quality">
                    <span className="mb-0 button" onClick={ handleShowCreateQualityModal }>Uusi laadunvalvontaraportti
                    <ActionIcon
                        className="ml-5"
                        icon={ faPlusCircle }
                    />
                    </span>
                </Can>
            </div>

      <div className="locations-list-layout">
        <div className="text">
          <Can I="read" a="Location">
            <h2 className="mb-5">Laadunvalvonta</h2>
            <Form>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="location"
                  id="location"
                  onChange={ (e) => handleChangeLocation(e.target.value) }
                >
                  <option value="">Valitse kohde</option>
                  {
                    locations && locations.map(location => (
                      <Can I="read" this={location}>
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      </Can>
                    ))
                  }
                </Form.Control>
              </Form.Group>
            </Form>
            { selectedLocation &&
              <div className="mt-5">
                <LocationItem
                  location={ selectedLocation }
                  onSelect={ setSelectedLocation }
                  onShow={ handleShowLocationModal } />
              </div>
            }
          </Can>
          <h6 className="mt-2 mb-2">Raporttiarkisto</h6>
          <Can I="read" a="Quality">
                <>
                    { qualities && qualities.map(quality => (
                        <Can I="read" this={quality}>
                            <QualityItem
                                key={ quality.id }
                                quality={ quality }
                                onSelect={ setSelectedQuality }
                                onShow={ handleShowQualityModal }
                                onShowEdit={ handleShowEditQualityModal }
                                onShowDelete={ handleShowDeleteQualityModal } />
                        </Can>
                    )) }
                </>
            </Can>
            </div>
        <div className="illustration">
          <img src={ illustration } alt="" />
        </div>
      </div>

            <Modal
                title="Laadunvalvontaraportti"
                size="lg"
                show={ showQualityModal }
                onHide={ handleRefreshView }
            >
                <QualityItem quality={ selectedQuality } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleRefreshView }>
                        Sulje
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Lisää Laadunvalvontaraportti"
                size="lg"
                show={ showCreateQualityModal }
                onHide={ handleHideCreateQualityModal }
            >
                <QualityForm
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateQualityModal }
                    onSubmit={ handleCreateQuality }
                />
            </Modal>
            <Modal
                title="Muokkaa Laadunvalvontaraporttia"
                size="lg"
                show={ showEditQualityModal }
                onHide={ handleHideEditQualityModal }
            >
                <QualityForm
                    quality={ selectedQuality }
                    isSendingData={ isSendingData }
                    onCancel={ handleHideEditQualityModal }
                    onSubmit={ handleUpdateQuality }
                />
            </Modal>
            <Modal
                title="Poista Laadunvalvontaraportti"
                size="lg"
                show={ showDeleteQualityModal }
                onHide={ handleHideDeleteQualityModal }
            >
                <QualityItem quality={ selectedQuality } />
                <p>Haluatko varmasti poistaa tämän Laadunvalvontaraportti? Tätä toimintoa ei voi peruuttaa.</p>
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideDeleteQualityModal }>
                        Peruuta
                    </Button>
                    <Button className="ml-3" variant="danger" onClick={ handleDeleteQuality }>
                        Kyllä
                    </Button>
                </div>
            </Modal>


      <Modal
        title="Kohde"
        show={ showLocationModal }
        onHide={ handleHideLocationModal }
      >
        <LocationItem location={ selectedLocation } />
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideLocationModal }>
            Sulje
          </Button>
        </div>
      </Modal>
      <Modal
        title="Kohde"
        show={ showLocationModal }
        onHide={ handleHideLocationModal }
      >
        <LocationItem location={ selectedLocation } />
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideLocationModal }>
            Sulje
          </Button>
        </div>
      </Modal>
      <Modal
        title="Lisää tiedosto"
        show={ showUploadModal }
        onHide={ handleRefreshView }
      >
        <UploadFiles
          onCancel={ handleHideUploadModal } />
      </Modal>
    </Page>
  );
}
