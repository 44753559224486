import http from "../http-common";

class UserService {
  getAll() {
    return http.get(`/users`);
  }

  getManagers() {
    return http.get('/users/byrole/3')
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post(`/users`, data);
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

  getPublicContent() {
    return http.get(`/test/all`);
  }

  getUserBoard() {
    return http.get(`/test/user`);
  }

  getAdminBoard() {
    return http.get(`/test/admin`);
  }
}

export default new UserService();
