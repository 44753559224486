import http from "../http-common";

class UploadFilesService {
  upload(values, onUploadProgress) {
    const { categoryId, locationId, file } = values;

    let formData = new FormData();
    formData.append("categoryId", categoryId);
    formData.append("locationId", locationId);
    formData.append("file", file);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles(category = null, location = 0) {
    return http.get("/files?category="+category+"&location="+location);
  }
}

export default new UploadFilesService();
