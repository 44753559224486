import React from 'react';
import logo from '../assets/images/logo-mtb.png';
import Button from 'react-bootstrap/Button';
import './css/intro.style.css';

export default function Introduction({
    onToggle
}) {
    return (
        <div className="introduction">
            <img src={ logo } className="mtb-logo"></img>
            <h2>Tervetuloa palvelumme äärelle!</h2>
            <p>MTB Palvelut toimittaa yrityksille ja julkiselle sektorille siivous- ja kierrätys palvelukokonaisuuksia, jotka mahdollistavat asiakkaiden keskittymisen erityisosaamiseensa.</p>
            <Button className="introduction-toggle" onClick={ onToggle }>Kirjaudu</Button>
        </div>
    );
}