import http from "../http-common";

const user = JSON.parse(window.localStorage.getItem('user'));
const userId = user ? user.id : null;

class QualityDataService {
  getAll() {
    return http.get(`/qualities`);
  }

  getAllByLoc(id) {
    return http.get(`/qualities/bylocation/${id}`);
  }

  get(id) {
    return http.get(`/qualities/${id}`);
  }

  create(data) {
    return http.post(`/qualities`, { ...data, userId });
  }

  update(id, data) {
    return http.put(`/qualities/${id}`, data);
  }

  delete(id) {
    return http.delete(`/qualities/${id}`);
  }

  deleteAll() {
    return http.delete(`/qualities`);
  }

  findByName(name) {
    return http.get(`/qualities?name=${name}`);
  }
}

export default new QualityDataService();
