export const UserRole = {
  CUSTOMER: 'ROLE_USER',
  CUSTOMER_ADMIN: 'ROLE_CUSTOMERADMIN',
  SUPERVISOR: 'ROLE_MANAGER',
  SALES: 'ROLE_SALES',
  ADMIN: 'ROLE_ADMIN'
};

export const errors = {
  form: {
    required: 'Ole hyvä ja täytä vaadittu kenttä',
    invalidEmail: 'Ole hyvä ja syötä sähköpostiosoite oikeassa muodossa',
    invalidConfirmPassword: 'Salasanat eivät täsmää'
  }
}