import React from 'react';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';

const validationSchema = Yup.object({
  name: Yup.string()
    .required(errors.form.required),
  email: Yup.string().email(errors.form.invalidEmail)
    .required(errors.form.required),
  address: Yup.string()
    .required(errors.form.required),
  phone: Yup.string()
    .required(errors.form.required)
});

export default function CustomerForm({
  onSubmit,
  onCancel,
  isSendingData,
  customer
}) {
  return (
    <>
      <AppLoader active={ isSendingData } component={ true } />
      <Formik
        initialValues={{
          name: customer ? customer.name : '',
          email: customer ? customer.email : '',
          address: customer ? customer.address : '',
          phone: customer ? customer.phone : ''
        }}
        validationSchema={ validationSchema}
        onSubmit={ onSubmit }
      >
        { props  => (
          <Form
            noValidate
            onSubmit={ props.handleSubmit }>
              <TextInput
                name="name"
                label="Nimi"
                placeholder="Nimi"
                formik={ props }
                required
              />
              <TextInput
                name="email"
                label="Sähköpostiosoite"
                placeholder="Sähköpostiosoite"
                formik={ props }
              />
              <TextInput
                as="textarea"
                name="address"
                label="Osoite"
                placeholder="Osoite"
                formik={ props }
              />
              <TextInput
                name="phone"
                label="Puhelinnumero"
                placeholder="Puhelinnumero"
                formik={ props }
              />
            <div className="align-right mt-4">
              <Button
                onClick={ () => {
                  onCancel();
                }}
                className="mr-3"
                type="button"
                variant="secondary"
              >
                Peruuta
              </Button>
              <Button
                type="submit"
              >
                Tallenna
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
