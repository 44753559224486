import React, { useEffect, useState } from "react";
import { subject as a } from "@casl/ability";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AppLoader from "./appLoader.component";
import Can from "./can.component";
import UploadedFiles from "./uploadedFiles.component";
import AuthService from "../services/auth.service";
import LocationDataService from "../services/location.service";
import UploadService from "../services/upload-files.service";
import { UserRole } from "../enums";
import { hasRequiredRoles } from "../utils";

export default function UploadFiles({ onCancel }) {
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingCategories, setIsFetchingCategories] = useState(true);
  const [isFetchingLocations, setIsFetchingLocations] = useState(true);
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);

  const [categories, setCategories] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [locationId, setLocationId] = useState(null);

  async function getFiles() {
    try {
      setIsFetchingFiles(true);
      const response = await UploadService.getFiles(categoryId,locationId);
      setFileInfos(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingFiles(false);
    }
  }

  function handleChangeCategory(id) {
    const categoryId = id ? categories.find(category => category.id === parseInt(id, 10)).id : null;
    setCategoryId(categoryId);
  }

  function handleChangeLocation(id) {
    const locationId = id ? locations.find(location => location.id === parseInt(id, 10)).id : null;
    setLocationId(locationId);
  }

  function handleSelectFile(e) {
    setSelectedFiles(e.target.files);
  }

  async function handleUpload() {
    const payload = { categoryId, locationId, file: selectedFiles[0] };
    try {
      setIsSendingData(true);
      await UploadService.upload(payload);
      await getFiles();
    } catch (error) {
      setError('Tiedoston lisääminen ei onnistunut.');
    } finally {
      setIsSendingData(false);
      setSelectedFiles(null);
    }
  }

  useEffect(() => {
    async function getCategories() {
      try {
        setIsFetchingCategories(true);
        setCategories([
          // { id: 1, name: 'Työtilaus' },
          { id: 2, name: 'Sopimus' },
          { id: 3, name: 'Pohjakuva' },
          { id: 4, name: 'Työohjeet' },
          { id: 5, name: 'Siivousvälineet/muuta infoa' },
          // { id: 6, name: 'Siivousaineet' },
          // { id: 7, name: 'Muut tärkeät työtavat' },
          { id: 8, name: 'Laadunvalvontakuvat' }
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetchingCategories(false);
      }
    }
    getCategories();

    async function getLocations() {
      try {
        setIsFetchingLocations(true);
        const response = hasRequiredRoles([UserRole.ADMIN]) ?
          await LocationDataService.getAll() :
          await LocationDataService.getAllByCustomer(AuthService.getCurrentUser().customerId);
        const data = response.data.map(location => a('Location', location));
        setLocations(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetchingLocations(false);
      }
    }
    getLocations();
    getFiles();
  }, []);

  return (
    <>
      <AppLoader
        active={ isFetchingCategories || isFetchingLocations || isFetchingFiles || isSendingData }
        component={ true } />

      <Form noValidate>
        <Form.Group>
          <Form.Label>Tiedoston kategoria</Form.Label>
          <Form.Control
            required
            as="select"
            name="category"
            onChange={ (e) => handleChangeCategory(e.target.value) }>
            <option value="">Valitse kategoria</option>
            {
              categories && categories.map((category) => (
                <option key={ category.id } value={ category.id }>
                  { category.name }
                </option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Tiedoston kohde</Form.Label>
          <Form.Control
            required
            as="select"
            name="location"
            onChange={ (e) => handleChangeLocation(e.target.value) }>
            <option value="">Valitse kohde</option>
            {
              locations && locations.map(location => (
                <Can I="read" this={ location }>
                  <option key={ location.id } value={ location.id }>
                    { location.name }
                  </option>
                </Can>
              ))
            }
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Tiedosto</Form.Label>
          <Form.File
            className="align-self-center"
            onChange={ handleSelectFile } />
        </Form.Group>

        { error &&
          <Alert variant="danger" dismissible onClick={ (e) => setError(null) }>
            <Alert.Heading>Virhe</Alert.Heading>
            <p>{ error }</p>
          </Alert>
        }

        <div className="align-right mt-4">
          <Button
            onClick={ () => {
              onCancel();
            }}
            className="mr-3"
            type="button"
            variant="secondary"
          >
            Peruuta
          </Button>
          <Button
            className="align-self-center"
            variant="primary"
            disabled={ !selectedFiles }
            onClick={ handleUpload }>
            Lisää
          </Button>
        </div>
      </Form>
      <h6>Tiedostolistaus</h6>
      <UploadedFiles files={ fileInfos } readonly />
    </>
  );
}
