import React from 'react';
import BSModal from 'react-bootstrap/Modal';

export default function Modal({
  title,
  children,
  ...rest
}) {
  return (
    <BSModal
      { ...rest }
    >
      <BSModal.Header closeButton>
        <BSModal.Title>{ title }</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>
        { children }
      </BSModal.Body>
    </BSModal>
  );
}
