import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import WorkorderForm from './forms/workorderForm.component';
import WorkorderItem from './workorderItem.component';
import Modal from './modal.component';
import Page from './page.component';
import WorkorderDataService from '../services/workorder.service';

import './css/workorders.style.css';

export default function Workorders() {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingWorkorders, setIsFetchingWorkorders] = useState(true);

    const [workorders, setWorkorders] = useState([]);
    const [selectedWorkorder, setSelectedWorkorder] = useState(null);

    const [showWorkorderModal, setShowWorkorderModal] = useState(false);
    const handleShowWorkorderModal = () => setShowWorkorderModal(true);
    const handleHideWorkorderModal = () => setShowWorkorderModal(false);
    const [showCreateWorkorderModal, setShowCreateWorkorderModal] = useState(false);
    const handleShowCreateWorkorderModal = () => setShowCreateWorkorderModal(true);
    const handleHideCreateWorkorderModal = () => setShowCreateWorkorderModal(false);
    const [showEditWorkorderModal, setShowEditWorkorderModal] = useState(false);
    const handleShowEditWorkorderModal = () => setShowEditWorkorderModal(true);
    const handleHideEditWorkorderModal = () => setShowEditWorkorderModal(false);
    const [showDeleteWorkorderModal, setShowDeleteWorkorderModal] = useState(false);
    const handleShowDeleteWorkorderModal = () => setShowDeleteWorkorderModal(true);
    const handleHideDeleteWorkorderModal = () => setShowDeleteWorkorderModal(false);

    async function handleCreateWorkorder(values) {
      try {
        setIsSendingData(true);
        await WorkorderDataService.create(values);
        setIsSendingData(false);
        const response = await WorkorderDataService.getAll();
        const data = response.data.map(workorder => a('Workorder', workorder));
        setWorkorders(data);
        setShowCreateWorkorderModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleUpdateWorkorder(values) {
      try {
        setIsSendingData(true);
        await WorkorderDataService.update(selectedWorkorder.id, values);
        setIsSendingData(false);
        const response = await WorkorderDataService.getAll();
        const data = response.data.map(workorder => a('Workorder', workorder));
        setWorkorders(data);
        setShowEditWorkorderModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleDeleteWorkorder() {
      try {
        setIsSendingData(true);
        await WorkorderDataService.delete(selectedWorkorder.id);
        setSelectedWorkorder(null);
        setIsSendingData(false);
        const response = await WorkorderDataService.getAll();
        const data = response.data.map(workorder => a('Workorder', workorder));
        setWorkorders(data);
        setShowDeleteWorkorderModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    useEffect(() => {
      async function getWorkorders() {
        try {
          setIsFetchingWorkorders(true);
          const response = await WorkorderDataService.getAll();
          const data = response.data.map(workorder => a('Workorder', workorder));
          setWorkorders(data);
          setIsFetchingWorkorders(false);
        } catch(error) {
          console.log(error);
          setIsFetchingWorkorders(false);
        }
      }
      getWorkorders();
    }, []);

    return (
        <Page center>
            <AppLoader active={ isFetchingWorkorders } component={ true } />

            <div
                className="vertical-center mt-5 mb-5"
            >
                <Can I="create" a="Workorder">
                    <span className="mb-0 button" onClick={ handleShowCreateWorkorderModal }>Luo uusi työtilaus
                    <ActionIcon
                        className="ml-5"
                        icon={ faPlusCircle }
                    />
                    </span>
                </Can>
            </div>
            <h2>Työtilaukset</h2>
            <Can I="read" a="Workorder">
                <>
                    { workorders && workorders.map(workorder => (
                        <Can I="read" this={workorder}>
                            <WorkorderItem
                                key={ workorder.id }
                                workorder={ workorder }
                                onSelect={ setSelectedWorkorder }
                                onShow={ handleShowWorkorderModal }
                                onShowEdit={ handleShowEditWorkorderModal }
                                onShowDelete={ handleShowDeleteWorkorderModal } />
                        </Can>
                    )) }
                </>
            </Can>

            <Modal
                title="Työtilaus"
                size="lg"
                show={ showWorkorderModal }
                onHide={ handleHideWorkorderModal }
            >
                <WorkorderItem workorder={ selectedWorkorder } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideWorkorderModal }>
                        Sulje
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Lisää työtilaus"
                size="lg"
                show={ showCreateWorkorderModal }
                onHide={ handleHideCreateWorkorderModal }
            >
                <WorkorderForm
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateWorkorderModal }
                    onSubmit={ handleCreateWorkorder }
                />
            </Modal>
            <Modal
                title="Muokkaa työtilausta"
                size="lg"
                show={ showEditWorkorderModal }
                onHide={ handleHideEditWorkorderModal }
            >
                <WorkorderForm
                    workorder={ selectedWorkorder }
                    isSendingData={ isSendingData }
                    onCancel={ handleHideEditWorkorderModal }
                    onSubmit={ handleUpdateWorkorder }
                />
            </Modal>
            <Modal
                title="Poista työtilaus"
                size="lg"
                show={ showDeleteWorkorderModal }
                onHide={ handleHideDeleteWorkorderModal }
            >
                <WorkorderItem workorder={ selectedWorkorder } />
                <p>Haluatko varmasti poistaa tämän työtilauksen? Tätä toimintoa ei voi peruuttaa.</p>
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideDeleteWorkorderModal }>
                        Peruuta
                    </Button>
                    <Button className="ml-3" variant="danger" onClick={ handleDeleteWorkorder }>
                        Kyllä
                    </Button>
                </div>
            </Modal>
        </Page>
    );
}
