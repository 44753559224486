import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import WorkmanualForm from './forms/workmanualForm.component';
import WorkmanualItem from './workmanualItem.component';
import Modal from './modal.component';
import Page from './page.component';
import WorkmanualDataService from '../services/workmanual.service';

import './css/workmanuals.style.css';

export default function Workmanuals() {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingWorkmanuals, setIsFetchingWorkmanuals] = useState(true);

    const [workmanuals, setWorkmanuals] = useState([]);
    const [selectedWorkmanual, setSelectedWorkmanual] = useState(null);

    const [showWorkmanualModal, setShowWorkmanualModal] = useState(false);
    const handleShowWorkmanualModal = () => setShowWorkmanualModal(true);
    const handleHideWorkmanualModal = () => setShowWorkmanualModal(false);
    const [showCreateWorkmanualModal, setShowCreateWorkmanualModal] = useState(false);
    const handleShowCreateWorkmanualModal = () => setShowCreateWorkmanualModal(true);
    const handleHideCreateWorkmanualModal = () => setShowCreateWorkmanualModal(false);
    const [showEditWorkmanualModal, setShowEditWorkmanualModal] = useState(false);
    const handleShowEditWorkmanualModal = () => setShowEditWorkmanualModal(true);
    const handleHideEditWorkmanualModal = () => setShowEditWorkmanualModal(false);
    const [showDeleteWorkmanualModal, setShowDeleteWorkmanualModal] = useState(false);
    const handleShowDeleteWorkmanualModal = () => setShowDeleteWorkmanualModal(true);
    const handleHideDeleteWorkmanualModal = () => setShowDeleteWorkmanualModal(false);

    async function handleCreateWorkmanual(values) {
      try {
        setIsSendingData(true);
        await WorkmanualDataService.create(values);
        setIsSendingData(false);
        const response = await WorkmanualDataService.getAll();
        const data = response.data.map(workmanual => a('Workmanual', workmanual));
        setWorkmanuals(data);
        setShowCreateWorkmanualModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleUpdateWorkmanual(values) {
      try {
        setIsSendingData(true);
        await WorkmanualDataService.update(selectedWorkmanual.id, values);
        setIsSendingData(false);
        const response = await WorkmanualDataService.getAll();
        const data = response.data.map(workmanual => a('Workmanual', workmanual));
        setWorkmanuals(data);
        setShowEditWorkmanualModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleDeleteWorkmanual() {
      try {
        setIsSendingData(true);
        await WorkmanualDataService.delete(selectedWorkmanual.id);
        setSelectedWorkmanual(null);
        setIsSendingData(false);
        const response = await WorkmanualDataService.getAll();
        const data = response.data.map(workmanual => a('Workmanual', workmanual));
        setWorkmanuals(data);
        setShowDeleteWorkmanualModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    useEffect(() => {
      async function getWorkmanuals() {
        try {
          setIsFetchingWorkmanuals(true);
          const response = await WorkmanualDataService.getAll();
          const data = response.data.map(workmanual => a('Workmanual', workmanual));
          setWorkmanuals(data);
          setIsFetchingWorkmanuals(false);
        } catch(error) {
          console.log(error);
          setIsFetchingWorkmanuals(false);
        }
      }
      getWorkmanuals();
    }, []);

    return (
        <Page center>
            <AppLoader active={ isFetchingWorkmanuals } component={ true } />

            <h2>Työohjeet</h2>
            <div
                className="vertical-center mt-5 mb-5"
                style={{
                    marginLeft: '64px'
                }}
            >
                <Can I="create" a="Workmanual">
                    <p className="mb-0">Luo työohje</p>
                    <ActionIcon
                        className="ml-5"
                        icon={ faPlusCircle }
                        onClick={ handleShowCreateWorkmanualModal } />
                </Can>
            </div>
            <Can I="read" a="Workmanual">
                <>
                    { workmanuals && workmanuals.map(workmanual => (
                        <Can I="read" this={workmanual}>
                            <WorkmanualItem
                                key={ workmanual.id }
                                workmanual={ workmanual }
                                onSelect={ setSelectedWorkmanual }
                                onShow={ handleShowWorkmanualModal }
                                onShowEdit={ handleShowEditWorkmanualModal }
                                onShowDelete={ handleShowDeleteWorkmanualModal } />
                        </Can>
                    )) }
                </>
            </Can>

            <Modal
                title="Työohje"
                size="lg"
                show={ showWorkmanualModal }
                onHide={ handleHideWorkmanualModal }
            >
                <WorkmanualItem workmanual={ selectedWorkmanual } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideWorkmanualModal }>
                        Sulje
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Lisää työohje"
                size="lg"
                show={ showCreateWorkmanualModal }
                onHide={ handleHideCreateWorkmanualModal }
            >
                <WorkmanualForm
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateWorkmanualModal }
                    onSubmit={ handleCreateWorkmanual }
                />
            </Modal>
            <Modal
                title="Muokkaa työohjetta"
                size="lg"
                show={ showEditWorkmanualModal }
                onHide={ handleHideEditWorkmanualModal }
            >
                <WorkmanualForm
                    workmanual={ selectedWorkmanual }
                    isSendingData={ isSendingData }
                    onCancel={ handleHideEditWorkmanualModal }
                    onSubmit={ handleUpdateWorkmanual }
                />
            </Modal>
            <Modal
                title="Poista työohje"
                size="lg"
                show={ showDeleteWorkmanualModal }
                onHide={ handleHideDeleteWorkmanualModal }
            >
                <WorkmanualItem workmanual={ selectedWorkmanual } />
                <p>Haluatko varmasti poistaa tämän työohjeen? Tätä toimintoa ei voi peruuttaa.</p>
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideDeleteWorkmanualModal }>
                        Peruuta
                    </Button>
                    <Button className="ml-3" variant="danger" onClick={ handleDeleteWorkmanual }>
                        Kyllä
                    </Button>
                </div>
            </Modal>
        </Page>
    );
}
