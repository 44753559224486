import React from 'react';
import { Spinner } from 'react-bootstrap';
import './css/appLoader.style.css';

const AppLoader = ({
    active = false,
    component = false
}) => {
    const loader = active ?
        <div className={ `app-loader${ component ? ' component-loader' : '' }` }>
            <Spinner
                animation="border"
                role="status"
                variant={ component ? 'secondary' : 'light' }
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div> :
        null;
    return loader;
};

export default AppLoader;