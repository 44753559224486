import React, { useState } from "react";

import logo from '../assets/images/logo-blim.png';
import plant from '../assets/images/kuvitus_plant.png';
import cleaner from '../assets/images/kuvitus_siivoojat.png';
import './css/login.style.css';
import LoginForm from "./loginForm.component";
import Introduction from "./intro.component";

export default function Login() {
  const [ showLoginForm, setShowLoginForm ] = useState(false);

    return (
      <div className="login-screen-wrapper">
        <div className="login-screen-header bg-primary">
          <img src={ logo }></img>
        </div>
        <div className="login-screen-content">
          <div className="login-illustration left">
            <img src={ plant }></img>
          </div>
          <div className="login-center">
            { showLoginForm ?
              <LoginForm /> :
              <Introduction onToggle={ () => setShowLoginForm(true) } />
            }
          </div>
          <div className="login-illustration right">
            <img src={ cleaner }></img>
          </div>
        </div>
      </div>

    );
}
