import axios from "axios";
import authHeader from './services/auth-header';

const api = axios.create({
//  baseURL: "http://localhost:5000/api",
  baseURL: "https://mtb.tokka.solutions/api",
  headers: authHeader()
});

api.interceptors.request.use(function (config) {
  config.headers = {
      ...config.headers,
      ...authHeader()
  };
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default api;