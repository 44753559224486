import React from 'react';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';

export default function UserForm({
  onSubmit,
  onCancel,
  isSendingData
}) {
  const validationSchema = Yup.object({
    password: Yup.string()
        .required(errors.form.required),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], errors.form.invalidConfirmPassword)
        .required(errors.form.required)
  });

  return (
    <>
      <AppLoader active={ isSendingData } component={ true } />
      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validationSchema={ validationSchema }
        onSubmit={ onSubmit }
      >
        { props  => (
          <Form
            noValidate
            onSubmit={ props.handleSubmit }>
              <TextInput
                type="password"
                name="password"
                label="Salasana"
                placeholder="Salasana"
                formik={ props }
                required
              />
              <TextInput
                type="password"
                name="confirmPassword"
                label="Salasana uudelleen"
                placeholder="Salasana uudelleen"
                formik={ props }
                required
              />
            <div className="align-right mt-4">
              { onCancel && <Button
                onClick={ () => {
                  onCancel();
                }}
                className="mr-3"
                type="button"
                variant="secondary"
              >
                Peruuta
              </Button> }
              <Button
                type="submit"
                disabled={ !props.dirty }
              >
                Tallenna
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
