import React, { useEffect, useMemo, useState } from 'react';
import { filter, identity, map } from 'lodash';
import { subject as a } from '@casl/ability';
import Form from "react-bootstrap/Form";
import { Formik, Field } from 'formik';
import { Button } from 'react-bootstrap';
import AppLoader from '../appLoader.component';
import Can from '../can.component';
import api from '../../http-common';
import customerService from '../../services/customer.service';
import locationService from '../../services/location.service';
import Select from 'react-select'
import { resolveRolesToId, resolveWorklocationsToId } from '../../utils';

export default function UserLocationsForm({
  onSubmit,
  onCancel,
  isSendingData,
  user
}) {
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false);
  const [customers, setCustomers] = useState();
  const [roles, setRoles] = useState();
  const [locations, setLocations] = useState();

  useEffect(() => {
    async function getRoles() {
      try {
        setIsFetchingRoles(true);
        const response = await api.get('/roles');
        const { data } = response;
        setRoles(data);
        setIsFetchingRoles(false);
      } catch(error) {
        console.log(error);
        setIsFetchingRoles(false);
      }
    }
    getRoles();

    async function getCustomers() {
      try {
        setIsFetchingCustomers(true);
        const response = await customerService.getAll();
        const data = response.data.map(customer => a('Customer', customer));
        setCustomers(data);
        setIsFetchingCustomers(false);
      } catch(error) {
        console.log(error);
        setIsFetchingCustomers(false);
      }
    }
    getCustomers();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    async function getLocations() {
      const { customerId } = user;
      const locationCall = customerId ? locationService.getAllByCustomer(customerId) : locationService.getAll();
      try {
        setIsFetchingLocations(true);
        const response = await locationCall;
        const data = response.data.map(({ id, name }) => ({
          label: name,
          value: id
        }));
        setLocations(data);
        setIsFetchingLocations(false);
      } catch(error) {
        console.log(error);
        setIsFetchingLocations(false);
      }
    }
    getLocations();
  }, [user]);

  if (user && roles) {
    const roleId = resolveRolesToId(user.roles);
    const role = roles.find(role => role.id === roleId);
    user = {
      ...user,
      role: role ? role.name : ''
    };
  }

  if (user && user.customerId && customers) {
    const customer = customers.find(customer => customer.id === user.customerId);
    user = {
      ...user,
      customer: customer ? customer.name : ''
    };
  }

  const worklocationIds = resolveWorklocationsToId(user.worklocations);

  return (
    <>
      <AppLoader
        active={ isFetchingCustomers || isFetchingLocations || isFetchingRoles || isSendingData }
        component={ true }
      />
        { locations &&
          <Formik
            initialValues={{
              worklocations: user ? formatWorklocations(worklocationIds, locations): []
            }}
            onSubmit={ (values) => {
              values = {
                ...values,
                worklocations: values.worklocations.map(location => location.value)
              };
              onSubmit(values);
            }}
          >
            { props  => (
              <Form
                noValidate
                onSubmit={ props.handleSubmit }>
                  <Form.Group>
                    <Form.Label>Käyttäjätunnus</Form.Label>
                    <p>{ user.username }</p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Sähköpostiosoite</Form.Label>
                    <p>{ user.email }</p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Käyttäjän rooli</Form.Label>
                    <p>{ user.role }</p>
                  </Form.Group>
                  { user.customerId &&
                    <Form.Group>
                      <Form.Label>Käyttäjän asiakkuus</Form.Label>
                      <p>{ user.customer }</p>
                    </Form.Group>
                  }
                  <Can I="read" a="User">
                    <Form.Group>
                      <Form.Label>Käyttäjän kohteet</Form.Label>
                      <Field name="worklocations">
                        {({ field }) => (
                          <Select
                            isDisabled={ !locations }
                            isMulti={ true }
                            value={ field.value}
                            onChange={ (value) => field.onChange({ target: { name: field.name, value }}) }
                            options={ locations }
                          />
                        )}
                      </Field>
                    </Form.Group>
                  </Can>
                <div className="align-right mt-4">
                  <Button
                    onClick={ onCancel }
                    className="mr-3"
                    type="button"
                    variant="secondary"
                  >
                    Peruuta
                  </Button>
                  <Button
                    type="submit"
                    disabled={ user && !props.dirty }
                  >
                    Tallenna
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        }
    </>
  );
}

function formatWorklocations(worklocationIds, locations) {
  return filter(map(worklocationIds, worklocation => locations.find(location => location.value === worklocation), identity));
}
