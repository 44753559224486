import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import './css/locationWorkingways.style.css';

export default function LocationWorkingways({ workingways }) {
  const [selectedWorkingways, setSelectedWorkingways] = useState([]);

  function handleToggleSelectWorkingway(workingway) {
    if (selectedWorkingways.some((selectedWorkingway) => selectedWorkingway.name === workingway.name)) {
      setSelectedWorkingways(selectedWorkingways.filter((selectedWorkingway) => selectedWorkingway.name !== workingway.name));
    } else {
      setSelectedWorkingways([...selectedWorkingways, workingway]);
    }
  }

  function isSelectedWorkingway(workingway) {
    return selectedWorkingways.some((selectedWorkingway) => selectedWorkingway.name === workingway.name);
  }

  return (
    <>
      <ListGroup>
        { workingways && workingways.map((workingway, index) => (
          <ListGroup.Item
            key={ index }
            onClick={ () => handleToggleSelectWorkingway(workingway) }>
            <div className="list-group-item-content">
              <a href={ workingway.url } target="_blank" rel="noopener noreferrer">
                 {workingway.name}
              </a>
              {
                <div className={ `list-group-item-checkbox ${ isSelectedWorkingway(workingway) && 'active' }` }>
                  {
                    isSelectedWorkingway(workingway) ?
                      <FontAwesomeIcon icon={faCheckSquare} /> :
                      <FontAwesomeIcon icon={faSquare} />
                  }
                </div>
              }
            </div>
          </ListGroup.Item>
        )) }
      </ListGroup>
    </>
  );
}
