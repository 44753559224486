import React, { useContext, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "./modal.component";
import Page from "./page.component";
import ChangePasswordForm from "./forms/changePasswordForm.component";
import userService from "../services/user.service";
import authService from "../services/auth.service";
import { useParams, useHistory } from "react-router-dom";
import { UserRole } from '../enums';
import { AbilityBuilder, Ability, detectSubjectType } from '@casl/ability';
import { AbilityContext } from './can.component';

import "./css/profile.style.css";
import AppLoader from "./appLoader.component";

export default function ResetPassword() {
  let { token } = useParams();
  const [isSendingData, setIsSendingData] = useState(false);
  const [ user, setUser ] = useState();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);
  const handleHideChangePasswordModal = () => setShowChangePasswordModal(false);
  const ability = useContext(AbilityContext);
  const history = useHistory();

  function updateAbility(ability, user) {
      const { can, rules } = new AbilityBuilder();
      for (let i = 0; i < user.roles.length; i++) {
          if (user.roles[i] === UserRole.ADMIN) {
              can('manage', 'Customer');
              can('manage', 'Location');
              can('manage', 'Method');
              can('manage', 'News');
              can('manage', 'Quality');
              can('manage', 'User');
              can('manage', 'Message');
              can('manage', 'Workorder');
              can('manage', 'Tool');
              can('manage', 'Chemical');
              can('manage', 'Workmanual');
              can('manage', 'Workingway');
          } else if (user.roles[i] === UserRole.SUPERVISOR) {
              can('read', 'Location', { id: { $in: user.locations } });
              can('read', 'Material');
              can('create', 'Message');
              can('read', 'News', { customerId: user.customerId });
              can('read', 'News', { customerId: 0 });
              can('read', 'Message', { locationId: { $in: user.locations } });
              can('read', 'Message', { userId: user.id });
              can('read', 'Quality');
              can('read', 'Workorder');
              can('read', 'Tool');
          } else if (user.roles[i] === UserRole.SALES) {
              can('create', 'Location');
              can('create', 'Customer');
              can('read', 'Material');
              can('create', 'Message');
              can('read', 'News', { customerId: user.customerId });
              can('read', 'News', { customerId: 0 });
              can('read', 'Message', { locationId: { $in: user.locations } });
              can('read', 'Message', { userId: user.id });
              can('read', 'Quality');
              can('read', 'Workorder');
              can('read', 'Tool');
          } else if (user.roles[i] === UserRole.CUSTOMER_ADMIN) {
              can('read', 'Location', { id: { $in: user.locations } });
              can('read', 'News', { customerId: user.customerId });
              can('read', 'News', { customerId: 0 });
              can('create', 'User');
              can(['read', 'update'], 'User', ['username', 'email', 'password'], { customerId: user.customerId });
              can('read', 'Message', { locationId: { $in: user.locations } });
              can('read', 'Message', { userId: user.id });
          } else if (user.roles[i] === UserRole.CUSTOMER) {
              can('read', 'Location', { id: { $in: user.locations } });
              can('create', 'Message');
              can('read', 'Message', { locationId: { $in: user.locations } });
              can('read', 'Message', { userId: user.id });
              // can('read', 'Method');
              can('read', 'News', { customerId: user.customerId });
              can('read', 'News', { customerId: 0 });
              can('read', 'News', { userId: user.id });
              can('read', 'Quality');
              can('read', 'Workorder', { assignee: 'me' });
              can(['read','create','update'], 'Workorder', { id: { $in: user.locations } });
              can('read', 'Tool');
              can('read', 'Chemical');
              can('read', 'Workmanual');
              can('read', 'Workingway');
          }
      }
      ability.update(rules);
  }
  
  async function getUser() {
    try {
      setIsSendingData(true);
      if(!user) {
        authService.resetPassword(token).then(
          response => {
            let data = response.data;
            if (data.accessToken) {
              console.log(data);
              setUser(data);
              localStorage.setItem("user", JSON.stringify(data));
              setIsSendingData(false);
            }    
          }
        );
      } else {
        setIsSendingData(false);
      }
    } catch(err){
      setIsSendingData(false);
    }
  }; 
 
  async function handleChangePassword(values) {
    try {
      setIsSendingData(true);
      await userService.update(user.id, values);
      setIsSendingData(false);
      updateAbility(ability, user);
      handleHideChangePasswordModal();
      history.push("/dashboard");
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  useEffect(() => {
    getUser();
  });

  return (
    <>
    <AppLoader active={ isSendingData } component={ true } />
    <img
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      alt="profile-img"
      className="profile-img-card"
    />

    <Page className="profile-content">
      <div className="profile-layout">
        <div className="text">
          <Button
            variant="primary mt-2"
            onClick={ handleShowChangePasswordModal }>
            Vaihda salasana
          </Button>

          <Modal
            title="Vaihda salasana"
            show={ showChangePasswordModal }
            onHide={ handleHideChangePasswordModal }
          >
            <ChangePasswordForm
              isSendingData={ isSendingData }
              onCancel={ handleHideChangePasswordModal }
              onSubmit={ handleChangePassword }
            />
          </Modal>
        </div>
      </div>
    </Page>
    </>
  );
}
