import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import './css/locationWorkmanuals.style.css';

export default function LocationWorkmanuals({ workmanuals }) {
  const [selectedWorkmanuals, setSelectedWorkmanuals] = useState([]);

  function handleToggleSelectWorkmanual(workmanual) {
    if (selectedWorkmanuals.some((selectedWorkmanual) => selectedWorkmanual.name === workmanual.name)) {
      setSelectedWorkmanuals(selectedWorkmanuals.filter((selectedWorkmanual) => selectedWorkmanual.name !== workmanual.name));
    } else {
      setSelectedWorkmanuals([...selectedWorkmanuals, workmanual]);
    }
  }

  function isSelectedWorkmanual(workmanual) {
    return selectedWorkmanuals.some((selectedWorkmanual) => selectedWorkmanual.name === workmanual.name);
  }

  return (
    <>
      <ListGroup>
        { workmanuals && workmanuals.map((workmanual, index) => (
          <ListGroup.Item
            key={ index }
            onClick={ () => handleToggleSelectWorkmanual(workmanual) }>
            <div className="list-group-item-content">
              <a href={ workmanual.url } target="_blank" rel="noopener noreferrer">
                 {workmanual.name}
              </a>
              {
                <div className={ `list-group-item-checkbox ${ isSelectedWorkmanual(workmanual) && 'active' }` }>
                  {
                    isSelectedWorkmanual(workmanual) ?
                      <FontAwesomeIcon icon={faCheckSquare} /> :
                      <FontAwesomeIcon icon={faSquare} />
                  }
                </div>
              }
            </div>
          </ListGroup.Item>
        )) }
      </ListGroup>
    </>
  );
}
