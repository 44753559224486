import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import ChemicalForm from './forms/chemicalForm.component';
import ChemicalItem from './chemicalItem.component';
import Modal from './modal.component';
import Page from './page.component';
import ChemicalDataService from '../services/chemical.service';

import './css/chemicals.style.css';

export default function Chemicals() {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingChemicals, setIsFetchingChemicals] = useState(true);

    const [chemicals, setChemicals] = useState([]);
    const [selectedChemical, setSelectedChemical] = useState(null);

    const [showChemicalModal, setShowChemicalModal] = useState(false);
    const handleShowChemicalModal = () => setShowChemicalModal(true);
    const handleHideChemicalModal = () => setShowChemicalModal(false);
    const [showCreateChemicalModal, setShowCreateChemicalModal] = useState(false);
    const handleShowCreateChemicalModal = () => setShowCreateChemicalModal(true);
    const handleHideCreateChemicalModal = () => setShowCreateChemicalModal(false);
    const [showEditChemicalModal, setShowEditChemicalModal] = useState(false);
    const handleShowEditChemicalModal = () => setShowEditChemicalModal(true);
    const handleHideEditChemicalModal = () => setShowEditChemicalModal(false);
    const [showDeleteChemicalModal, setShowDeleteChemicalModal] = useState(false);
    const handleShowDeleteChemicalModal = () => setShowDeleteChemicalModal(true);
    const handleHideDeleteChemicalModal = () => setShowDeleteChemicalModal(false);

    async function handleCreateChemical(values) {
      try {
        setIsSendingData(true);
        await ChemicalDataService.create(values);
        setIsSendingData(false);
        const response = await ChemicalDataService.getAll();
        const data = response.data.map(chemical => a('Chemical', chemical));
        setChemicals(data);
        setShowCreateChemicalModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleUpdateChemical(values) {
      try {
        setIsSendingData(true);
        await ChemicalDataService.update(selectedChemical.id, values);
        setIsSendingData(false);
        const response = await ChemicalDataService.getAll();
        const data = response.data.map(chemical => a('Chemical', chemical));
        setChemicals(data);
        setShowEditChemicalModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleDeleteChemical() {
      try {
        setIsSendingData(true);
        await ChemicalDataService.delete(selectedChemical.id);
        setSelectedChemical(null);
        setIsSendingData(false);
        const response = await ChemicalDataService.getAll();
        const data = response.data.map(chemical => a('Chemical', chemical));
        setChemicals(data);
        setShowDeleteChemicalModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    useEffect(() => {
      async function getChemicals() {
        try {
          setIsFetchingChemicals(true);
          const response = await ChemicalDataService.getAll();
          const data = response.data.map(chemical => a('Chemical', chemical));
          setChemicals(data);
          setIsFetchingChemicals(false);
        } catch(error) {
          console.log(error);
          setIsFetchingChemicals(false);
        }
      }
      getChemicals();
    }, []);

    return (
        <Page center>
            <AppLoader active={ isFetchingChemicals } component={ true } />

            <h2>Siivousaineet</h2>
            <div
                className="vertical-center mt-5 mb-5"
            >
                <Can I="create" a="Chemical">
                    <span className="mb-0 button" onClick={ handleShowCreateChemicalModal }>Luo siivousaine
                    <ActionIcon
                        className="ml-5"
                        icon={ faPlusCircle }
                    />
                    </span>
                </Can>
            </div>
            <Can I="read" a="Chemical">
                <>
                    { chemicals && chemicals.map(chemical => (
                        <Can I="read" this={chemical}>
                            <ChemicalItem
                                key={ chemical.id }
                                chemical={ chemical }
                                onSelect={ setSelectedChemical }
                                onShow={ handleShowChemicalModal }
                                onShowEdit={ handleShowEditChemicalModal }
                                onShowDelete={ handleShowDeleteChemicalModal } />
                        </Can>
                    )) }
                </>
            </Can>

            <Modal
                title="Siivousaine"
                size="lg"
                show={ showChemicalModal }
                onHide={ handleHideChemicalModal }
            >
                <ChemicalItem chemical={ selectedChemical } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideChemicalModal }>
                        Sulje
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Lisää siivousaine"
                size="lg"
                show={ showCreateChemicalModal }
                onHide={ handleHideCreateChemicalModal }
            >
                <ChemicalForm
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateChemicalModal }
                    onSubmit={ handleCreateChemical }
                />
            </Modal>
            <Modal
                title="Muokkaa siivousainetta"
                size="lg"
                show={ showEditChemicalModal }
                onHide={ handleHideEditChemicalModal }
            >
                <ChemicalForm
                    chemical={ selectedChemical }
                    isSendingData={ isSendingData }
                    onCancel={ handleHideEditChemicalModal }
                    onSubmit={ handleUpdateChemical }
                />
            </Modal>
            <Modal
                title="Poista siivousaine"
                size="lg"
                show={ showDeleteChemicalModal }
                onHide={ handleHideDeleteChemicalModal }
            >
                <ChemicalItem chemical={ selectedChemical } />
                <p>Haluatko varmasti poistaa tämän siivousaineen? Tätä toimintoa ei voi peruuttaa.</p>
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideDeleteChemicalModal }>
                        Peruuta
                    </Button>
                    <Button className="ml-3" variant="danger" onClick={ handleDeleteChemical }>
                        Kyllä
                    </Button>
                </div>
            </Modal>
        </Page>
    );
}
