import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import ReactQuill from 'react-quill';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from 'react-bootstrap';
import { TextInput } from './textInput.component';
import { errors } from '../../enums';
import AppLoader from '../appLoader.component';
import Can from '../can.component';

import 'react-quill/dist/quill.snow.css';

const validationSchema = Yup.object({
  name: Yup.string()
    .required(errors.form.required),
  description: Yup.string()
    .required(errors.form.required)
});

export default function ChemicalForm({
  onSubmit,
  onCancel,
  isSendingData,
  chemical
}) {
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


  return (
    <>
      <AppLoader active={ isSendingData } component={ true } />
      <Formik
        initialValues={{
          name: chemical ? chemical.name : '',
          description: chemical ? chemical.description : ''
        }}
        validationSchema={ validationSchema}
        onSubmit={ onSubmit }
      >
        { props  => (
          <Form
            noValidate
            onSubmit={ props.handleSubmit }>
              <TextInput
                name="name"
                label="Nimi"
                placeholder="Nimi"
                formik={ props }
                required
              />
              <Form.Group>
                  <Form.Label>Selite</Form.Label>
                  <Field name="description">
                    {({ field }) => <ReactQuill modules={modules} value={field.value} onChange={field.onChange(field.name)} />}
                  </Field>
              </Form.Group>
              <TextInput
                name="url"
                label="Verkko-osoite"
                placeholder="Url"
                formik={ props }
                required
              />
            <div className="align-right mt-4">
              <Button
                onClick={ () => {
                  onCancel();
                }}
                className="mr-3"
                type="button"
                variant="secondary"
              >
                Peruuta
              </Button>
              <Button
                type="submit"
              >
                Tallenna
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
