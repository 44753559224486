import React from 'react';
import logo from '../assets/images/logo-mtb.png';
import Button from 'react-bootstrap/Button';
import './css/intro.style.css';

export default function Privacy({}) {
    return (
        <div className="Privacy">
            <img src={ logo } className="mtb-logo"></img>
            <h1>Tietoja yksityisyyden suojasta</h1>
            <p><strong>Henkilötietojen käsittely</strong></p>
            <p>Sinun ei tarvitse antaa lainkaan henkilötietoja käydessäsi sivustollamme. Kun vierailet sivustollamme, laitteesi lähettää meille automaattisesti anonyymejä teknisiä tietoja (esim. IP-osoite, selaintyyppi ja tieto siitä, mistä olet saapunut sivustolle). Keräämme myös evästeiden avulla tietoja siitä, miten käytät sivustoa. Evästeet -osiossa on tästä tarkempaa tietoa.</p>
            <p>Joillakin sivuilla saatamme kysyä henkilötietojasi, jotta voimme ottaa sinuun yhteyttä.</p>
            <p>Emme luovuta henkilötietoja MTB Siivouspalvelu Oy:n tai sen kumppaneihin, osakkuusyhtiöihin, tytäryhtiöihin ja alihankkijoihin nähden ulkopuolisille tahoille. Kaikki asianomaiset alihankkijat ovat hyväksyneet henkilötietojenkäsittelyä koskevat ohjeemme.</p>
            <p><strong>Evästeet</strong></p>
            <p>Evästeet ovat pieniä tekstitiedostoja, joita verkkosivustot tallentavat tietokoneellesi tai mobiililaitteellesi, kun käyt sivustolla. Evästeet tallentavat erilaisia tietoja laitteestasi; esimerkiksi selaimen kielen, laitteen sijainnin ja tekniset ominaisuudet.</p>
            <p>Voimme aika ajoin tallentaa tietokoneellesi evästeissä seuraavia tietoja, edellyttäen että olet antanut suostumuksesi evästeiden tallentamiseen laitteellesi.</p>
            <p><strong>Verkkosivuston kävijäseurannan evästeet:</strong></p>
            <p>Käytämme Google Analytics -analytiikkapalvelua kerätäksemme ja tutkiaksemme tietoja siitä, miten sivustoa käytetään ja miten voimme parhaiten tarjota käyttäjille heidän tarvitsemiaan tietoja. Google Analytics tallentaa laitteellesi evästeissä tietoja, joiden avulla voimme nähdä mistä olet saapunut sivuille, millä laitteella, millä sivuilla vierailet ja kuinka kauan vietät sivuilla aikaa.</p>
            <p><strong>Evästeitä ei käytetä henkilöllisyyden tunnistamiseen</strong>, evästeitä käytetään ainoastaan edellä kuvattuun tarkoitukseen.</p>
            <p>Jos et halua vastaanottaa Google Analyticsin evästeitä, voit estää ne valitsemalla selaimessasi evästeet estävän asetuksen.</p>
            <p><a href="https://tools.google.com/dlpage/gaoptout">Estä Google Analytics-kävijäseuranna käyttö sivustollamme</a></p>
            <h3>Mainonnan palveluihin liittyvät evästeet</h3>
            <p>Käytämme Google Adwords-palvelua sivustomme markkinointiin. Google Adwords voi tallentaa laitteellesi evästeitä, joiden avulla voimme seurata mainoskampanjoidemme onnistumista ja kuinka mainoksista saapuneet käyttäjät viettävät aikaa sivustollamme.</p>
            <p>Jos et halua vastaanottaa Google Adwordsin evästeitä, voit estää ne valitsemalla selaimessasi evästeet estävän asetuksen. Voit myös hallita asetuksiasi osoitteessa&nbsp;<a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a></p>
            <p><strong>Yhteydenotot</strong></p>
            <p>Voit milloin tahansa ottaa yhteyttä meihin tarkistaaksesi, että henkilötietosi ovat oikein. Pyynnöstäsi oikaisemme tai poistamme puutteelliset, väärät tai vanhentuneet henkilötiedot.</p>
            <p>Jos olet antanut suostumuksesi henkilötietojen käsittelyyn, voit milloin tahansa peruuttaa suostumuksesi ottamalla yhteyttä meihin.</p>
            <p>Lisätietoja saat ottamalla yhteyttä yhteydenottolomakkeella osoitteessa <a href="https://www.mtb.fi/yhteystiedot/">https://www.mtb.fi/yhteystiedot/</a></p>
            <h3><strong>TIETOSUOJASELOSTE&nbsp;</strong></h3>
            <p><strong>MTB-SIIVOUSPALVELU OY ASIAKASREKISTERI</strong></p>
            <p>Henkilötietolain (523/99) 10 §</p>
            <p>EU:n yleinen tietosuoja-asetus 2016/679 (GDPR)</p>
            <p>Laadittu 18.5.2018/päivitetty 24.5.2018</p>
            <p>Tämä on tietosuojaseloste MTB-Siivouspalvelu Oy:n (myöhemmin MTB) asiakasrekisteristä. MTB on sitoutunut suojelemaan yksilöiden oikeuksia ja pitämään henkilötiedot turvassa. Asiakasrekisterin henkilötiedot ovat meille tärkeitä muun muassa asiakassopimuksen ylläpitämiseksi, jotta voimme hoitaa esimerkiksi tilauksia ja ostoja sekä laskutusta. Asiakasrekisterin tietoja ei jaeta ulkopuolisille tahoille. MTB pidättää oikeuden päivittää ja muuttaa tietosuojaselostetta, mutta muutoksista tiedotetaan rekisteröidyille lainsäädännön niin vaatiessa.</p>
            <h3>Rekisterinpitäjä</h3>
            <p>MTB-Siivouspalvelu Oy (0680535-2)<br />
            <a href="https://maps.google.com/?q=Westendintie+1&amp;entry=gmail&amp;source=g">Westendintie 1</a>&nbsp;B<br />
            02160 Espoo<br />
            09 42 43 5000 |&nbsp;<u><a href="mailto:asiakaspalvelu@mtb.fi">asiakaspalvelu@mtb.fi</a></u>&nbsp; Rekisteriasioita hoitava henkilö: Riku Backström&nbsp;<a href="mailto:riku.backstrom@mtb.fi">riku.backstrom@mtb.fi</a></p>
            <p>Rekisteröidyn oikeuksiin liittyvät pyynnöt:&nbsp;<u><a href="mailto:asiakaspalvelu@mtb.fi">asiakaspalvelu@mtb.fi</a></u>&nbsp;/ 09 42 43 5000</p>
            <h3>Henkilötietojen käsittelyn tarkoitus</h3>
            <p>Henkilötietoja kerätään asiakkaalta itseltään yhteydenoton, rekisteröitymisen sekä/tai palvelujen ostamisen yhteydessä ja niitä käsitellään asiakassuhteen tai muun asiallisen yhteyden perusteella henkilötietolain 8 §:n sallimissa ja edellyttämissä rajoissa. Henkilötietoja käsitellään asiakasviestintään, asiakasrekisterin ylläpitämiseen, muuhun asiakassuhteen hoitamiseen, hallintaan ja kehittämiseen, asiakkaan ja MTB:n oikeuksien ja velvollisuuksien toteuttamiseen, analysointiin sekä tilastointiin. Rekisterin sisältämiä asiakastietoja voidaan käyttää MTB:n suoramarkkinointiin, mikäli asiakas on itse antanut luvan suoramarkkinointiin. Jo rekisteröityjä henkilötietoja voidaan päivittää rekisteröidyn palautteesta tai pyynnöstä. Henkilötietoja voidaan poistaa rekisteröidyn vaatimuksesta tai asiakassuhteen päättymisen vuoksi, jos tähän ei ole laillista tai teknistä estettä (kts. rekisteröidyn oikeudet).</p>
            <h3>Rekisteriin kerättävä tietosisältö</h3>
            <p>Asiakasrekisteri voi sisältää seuraavia tietoja rekisteröidystä: asiakasnumero, yrityksen nimi, yrityksen toimiala ja henkilöluokka, yhteyshenkilön titteli, yrityksen osoite, yrityksen puhelinnumero, työsähköpostiosoite, markkinointilupa/ kielto, uutiskirjeiden luku- ja klikkaushistoria, tilausinformoinnin luvat/ kiellot, asiakaspalautteet sekä muu asiakkaan itse antama tieto ja palveluiden osto historia. &nbsp;Yritysasiakkailta voi olla annettuna yhteyshenkilön ja muiden yritys yhteyshenkilöiden nimet sekä yhteystiedot. MTB:n asiakasrekisterissä ei käsitellä erityisiä henkilökohtaisia tietoja.</p>
            <h3>Tietojen vastaanottajaryhmistä</h3>
            <p>MTB voi luovuttaa rekisterissä olevia henkilötietoja voimassaolevan lainsäädännön sallimissa ja velvoittavissa rajoissa. Henkilötietoja voidaan luovuttaa muille tahoille, kuten esimerkiksi viranomaisille, palveluntoimittajille ja alihankkijoille. MTB:n asiakas- sekä markkinointirekisteristä ei luovuteta säännönmukaisesti tietoja yrityksen ulkopuolelle. Rekisterinpitäjä voi kuitenkin luovuttaa asiakkaan nimen ja tarvittavat yhteystiedot myös kolmannelle osapuolelle, kuten alihankkijalle, jälleenmyyjälle tai tavarantoimittajalle, mahdollistaakseen että pyydetty palvelu tai tilaus voidaan täyttää. Nämä kolmannet tahot toimittavat puolestamme asiakkaan tarvitsemat tiedot, tuotteet tai palvelut. MTB varmistaa aina ennen tietojen luovuttamista, että sovittuja asiaankuuluvia salassapitovelvoitteita noudatetaan aina.</p>
            <h3>Henkilötietojen siirtäminen EU:n tai ETA:n ulkopuolelle</h3>
            <p>Tietoja ei siirretä EU:n tai ETA:n ulkopuolelle.&nbsp; Mikäli tietojen siirto EU- / ETA-alueen ulkopuolelle tulee palvelun toteuttamisen vuoksi tarpeelliseksi, MTB huolehtii sopimuksin lainsäädännön edellyttämällä tavalla tietosuojan riittävästä tasosta sekä tietojen siirrosta tehdään erilliset evaluoinnit ennen siirtoa.</p>
            <h3>Henkilötietojen säilytys- ja poistoaika</h3>
            <p>Rekisterin henkilötietoja säilytetään luottamuksellisina. MTB säilyttää tietoja niin kauan kun niitä tarvitaan siihen tarkoitukseen, jota varten ne on kerätty ja käsitelty, tai niin kauan kuin laki ja säännösten mukaiset tiedon säilyttämistä koskevat vaatimukset niin edellyttävät. Jos MTB säilyttää rekisteröidyn tietoja muihin tarkoituksiin kuin sopimuksen täytäntöön panemiseen (esimerkiksi hankinta-, kirjanpito- ja ennakkoperintälaista noudatettavaksi tuleviin säilytysaikoihin) kuitenkin tietoja säilytetään vain, jos se on kyseisen tarkoituksen kannalta tarpeellista ja/ tai laissa ja säännöksissä määrättyä.</p>
            <h3>Henkilötietojen suojaus</h3>
            <p>MTB varmistaa asiakkaiden henkilötietojen käsittelyn yleisen tietoturvan sekä henkilötietojen luottamuksellisuuden, eheyden ja käytettävyyden tarkoituksenmukaisin teknisin ja hallinnollisin toimenpitein. Tietokannat, joihin tietoja tallennetaan, ovat teknisesti ja fyysisesti suojattu siten, ettei niihin ole pääsyä yrityksen ulkopuolelta. Sähköisessä muodossa oleva asiakasrekisteri on suojattu salasanoilla. Kaikkien rekisteriä käyttävien henkilöiden käyttäjäoikeudet on rajattu henkilön roolien mukaan. Rekisteristä otetaan säännönmukaisesti varmuuskopiot pilvipalveluun. Rekisteri säilytetään suojatulla palvelimella ja varmistuksella. Manuaalisesti käsiteltävät, rekisteröityjen tietoja sisältävät asiakirjat säilytetään lukituissa tiloissa niin että vain käyttäjäoikeutetuilla on pääsy niihin.</p>
            <h3>Henkilötietojen käsittelijät</h3>
            <p>Tietojen käsittelyssä noudatetaan voimassa olevaa lainsäädäntöä, joka taataan organisaatioiden välisten sopimusten avulla. Ainoastaan niillä rekisterinpitäjän työtekijöillä ja rekisterinpitäjän lukuun toimivien yritysten työntekijöillä on pääsy tietoihin, joille se on työtehtäviensä hoitamisen vuoksi tarpeen.</p>
            <h3>Rekisteröidyn oikeudet</h3>
            <p>Rekisteröidyllä on EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet:</p>
            <ul>
            <li>Pyytää rekisterinpitäjältä pääsy häntä itseään koskeviin henkilötietoihin ja tarkastaa mitä tietoja hänestä on tallennettu asiakasrekisteriin. Sekä oikeus pyytää kyseisten tietojen oikaisemista, poistamista tai käsittelyn rajoittamista. Jos tiedot ovat virheellisiä tai puutteellisia, rekisteröidyllä on oikeus pyytää tietojen korjaamista, ellei lainsäädäntö sitä rajoita.</li>
            <li>Oikeus rajoittaa sekä vastustaa tietojen käsittelyä, jos käsittelyn jatkamiselle ei ole mitään hyväksyttävää syytä tai tietojen käsittely on lainvastaista. Rekisteröidyllä on aina oikeus kieltää häntä koskevien tietojen käsittely ja luovuttaminen suoramainontaan, etämyyntiin tai muuhun suoramarkkinointiin sekä mielipide- ja markkinatutkimukseen.</li>
            <li>Rekisteröidyllä on oikeus saada rekisterinpitäjälle toimittamansa henkilötiedot yleisesti käytetyssä ja koneellisesti luettavassa muodossa, sekä halutessaan siirtää kyseiset tiedot toiselle rekisterinpitäjälle.</li>
            <li>Mikäli rekisteröity kokee ettei rekisterinpitäjä ole käsitellyt henkilötietoja lainmukaisesti, voi rekisteröity tehdä valituksen valvontaviranomaiselle.</li>
            </ul>
            <p>Tarkastus-, oikaisu- tai muun pyynnön voi esittää ottamalla yhteyttä rekisteriasioita hoitavaan henkilöön.</p>
        </div>
    );
}