import React, { Component } from "react";

import UserService from "../services/user.service";

import AuthService from "../services/auth.service";

import { Link } from "react-router-dom";

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: "",
      showAdminBoard: false,
      content: ""
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    UserService.getAdminBoard().then(
      response => {
        this.setState({
          currentUser: user,
          showAdminBoard: user.roles.includes("ROLE_ADMIN"),  
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    const { showAdminBoard } = this.state;
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{this.state.content}</h3>
        <nav>
        {showAdminBoard && (
          <Link to={"/addCustomer"} className="nav-link">
            Lisää Asiakas
          </Link>
        )}

        {showAdminBoard && (
          <Link to={"/addLocation"} className="nav-link">
            Lisää Kohde
          </Link>
        )}

        {showAdminBoard && (
          <Link to={"/infoTexts"} className="nav-link">
            Infosivujen tekstit
          </Link>
        )}

        {showAdminBoard && (
          <Link to={"/cleaningProducts"} className="nav-link">
            Hallinnoi siivousaineita
          </Link>
        )}

        {showAdminBoard && (
          <Link to={"/tools"} className="nav-link">
            Hallinnoi siivousvälineitä
          </Link>
        )}

        {showAdminBoard && (
          <Link to={"/users"} className="nav-link">
            Käyttäjät ja oikeudet
          </Link>
        )}
        </nav>
        </header>
        <main>
        </main>
      </div>
    );
  }
}
