import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import LocationForm from './forms/locationForm.component';
import LocationItem from './locationItem.component';
import Modal from './modal.component';
import Page from './page.component';
import UploadFiles from './upload-files.component';
import UploadedFiles from "./uploadedFiles.component";
import LocationDataService from '../services/location.service';
import UploadService from "../services/upload-files.service";
import ListGroup from 'react-bootstrap/ListGroup';

import illustration from '../assets/images/kuvitus_siivoojat.png';
import './css/locations-list.style.css';
import LocationTools from './locationTools.component';
import LocationWorkingways from './locationWorkingways.component';
import LocationChemicals from './locationChemicals.component';
import LocationWorkmanuals from './locationWorkmanuals.component';
import ToolService from '../services/tool.service';
import ChemicalService from '../services/chemical.service';
import WorkingwayService from '../services/workingway.service';
import WorkmanualService from '../services/workmanual.service';
import QualityDataService from '../services/quality.service';
import QualityItem from './qualityItem.component';

export default function Locations() {
  const [isSendingData, setIsSendingData] = useState(false);
  const [isFetchingLocations, setIsFetchingLocations] = useState(true);

  const [fileInfos, setFileInfos] = useState([]);
  const [fileInfos2, setFileInfos2] = useState([]);
  const [fileInfos3, setFileInfos3] = useState([]);
  const [fileInfos4, setFileInfos4] = useState([]);
  const [fileInfos5, setFileInfos5] = useState([]);
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);

  const [toolInfos, setToolInfos] = useState([]);
  const [chemicalInfos, setChemicalInfos] = useState([]);
  const [selectedChemicals, setSelectedChemicals] = useState([]);
  const [workingwayInfos, setWorkingwayInfos] = useState([]);
  const [workmanualInfos, setWorkmanualInfos] = useState([]);
  const [isFetchingTools, setIsFetchingTools] = useState(true);
  const [isFetchingQualities, setIsFetchingQualities] = useState(true);

  const [qualities, setQualities] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(null);

  const [showQualityModal, setShowQualityModal] = useState(false);
  const handleShowQualityModal = () => setShowQualityModal(true);
  const handleHideQualityModal = () => setShowQualityModal(false);

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const handleShowLocationModal = () => setShowLocationModal(true);
  const handleHideLocationModal = () => setShowLocationModal(false);
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const handleShowCreateLocationModal = () => setShowCreateLocationModal(true);
  const handleHideCreateLocationModal = () => setShowCreateLocationModal(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const handleShowEditLocationModal = () => setShowEditLocationModal(true);
  const handleHideEditLocationModal = () => setShowEditLocationModal(false);
  const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
  const handleShowDeleteLocationModal = () => setShowDeleteLocationModal(true);
  const handleHideDeleteLocationModal = () => setShowDeleteLocationModal(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleHideUploadModal = () => setShowUploadModal(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const handleShowToolsModal = () => setShowToolsModal(true);
  const handleHideToolsModal = () => setShowToolsModal(false);
  const [showChemicalsModal, setShowChemicalsModal] = useState(false);
  const handleShowChemicalsModal = () => setShowChemicalsModal(true);
  const handleHideChemicalsModal = () => setShowChemicalsModal(false);
  const [showWorkmanualsModal, setShowWorkmanualsModal] = useState(false);
  const handleShowWorkmanualsModal = () => setShowWorkmanualsModal(true);
  const handleHideWorkmanualsModal = () => setShowWorkmanualsModal(false);
  const [showWorkingwaysModal, setShowWorkingwaysModal] = useState(false);
  const handleShowWorkingwaysModal = () => setShowWorkingwaysModal(true);
  const handleHideWorkingwaysModal = () => setShowWorkingwaysModal(false);

  async function handleCreateLocation(values) {
    try {
      setIsSendingData(true);
      await LocationDataService.create(values);
      setIsSendingData(false);
      const response = await LocationDataService.getAll();
      const data = response.data.map(location => a('Location', location));
      setLocations(data);
      setShowCreateLocationModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleUpdateLocation(values) {
    try {
      setIsSendingData(true);
      await LocationDataService.update(selectedLocation.id, values);
      setIsSendingData(false);
      const response = await LocationDataService.getAll();
      const data = response.data.map(location => a('Location', location));
      setLocations(data);
      setShowEditLocationModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  async function handleDeleteLocation() {
    try {
      setIsSendingData(true);
      await LocationDataService.delete(selectedLocation.id);
      setSelectedLocation(null);
      setIsSendingData(false);
      const response = await LocationDataService.getAll();
      const data = response.data.map(location => a('Location', location));
      setLocations(data);
      setShowDeleteLocationModal(false);
    } catch(error) {
      console.log(error);
      setIsSendingData(false);
    }
  }

  function handleChangeLocation(locationId) {
    const location = locationId ? locations.find(location => location.id === parseInt(locationId, 10)) : null;
    setSelectedLocation(location);
    getFiles(location);
    getTools();
    setSelectedChemicals(location.chemicals);
  }

  useEffect(() => {
    getQualities(selectedLocation);
  }, [selectedLocation]);


  async function getFiles(location) {
    try {
      setIsFetchingFiles(true);
      if(location){
        let response = await UploadService.getFiles(8, location.id);
        setFileInfos(response.data);
        response = await UploadService.getFiles(2, location.id);
        setFileInfos2(response.data);
        response = await UploadService.getFiles(3, location.id);
        setFileInfos3(response.data);
        response = await UploadService.getFiles(4, location.id);
        setFileInfos4(response.data);
        response = await UploadService.getFiles(5, location.id);
        setFileInfos5(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingFiles(false);
    }
  }

  async function getTools() {
    try {
      setIsFetchingTools(true);
      let response = await ToolService.getAll();
      setToolInfos(response.data);
      response = await ChemicalService.getAll();
      setChemicalInfos(response.data);
      response = await WorkingwayService.getAll();
      setWorkingwayInfos(response.data);
      response = await WorkmanualService.getAll();
      setWorkmanualInfos(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingTools(false);
    }
  }

  function handleRefreshView() {
    getFiles(selectedLocation);
    handleHideUploadModal();
  }

  async function getQualities(location) {
    try {
      setIsFetchingQualities(true);
      const response = await QualityDataService.getAllByLoc(location.id);
//      const response = await QualityDataService.getAll();
      const data = response.data.map(quality => a('Quality', quality));
      setQualities(data);
      setIsFetchingQualities(false);
    } catch(error) {
      console.log(error);
      setIsFetchingQualities(false);
    }
  };

useEffect(() => {
  async function getLocations() {
      try {
        setIsFetchingLocations(true);
        const response = await LocationDataService.getAll();
        const data = response.data.map(location => a('Location', location));
        setLocations(data);
        setIsFetchingLocations(false);
      } catch(error) {
        console.log(error);
        setIsFetchingLocations(false);
      } finally {
      }
    }
    getLocations();
    getFiles(null);
    getTools();
  }, []);

  useEffect(() => {
    if (locations && locations[0]) {
      handleChangeLocation(locations[0].id);
    }
    console.log(locations, '- Has changed')
  },[locations]) // <-- here put the parameter to listen

  return (
    <Page className="locations-list-content">
      <AppLoader active={ isFetchingTools || isFetchingFiles || isFetchingLocations ||isFetchingQualities } component={ true } />

      <div className="locations-list-layout">
        <div className="text accordionElms">
          <Can I="read" a="Location">
            <Can I="create" a="Location">
            <div className="buttonContainer">
              <span className="mb-0 button"  onClick={ handleShowCreateLocationModal }>Luo uusi kohde
              <ActionIcon
                className="ml-5"
                icon={ faPlusCircle }
              /></span>
            </div>
            <br/>
            </Can>
            <h2 className="mb-5">Kohteet</h2>
            <Form>
              <Form.Group>
                <Form.Control
                  as="select"
                  name="location"
                  id="location"
                  onChange={ (e) => handleChangeLocation(e.target.value) }
                >
                  <option value="">Valitse kohde</option>
                  {
                    locations && locations.map(location => (
                      <Can I="read" this={location}>
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      </Can>
                    ))
                  }
                </Form.Control>
              </Form.Group>
            </Form>
            { selectedLocation &&
              <div className="mt-5">
                <LocationItem
                  location={ selectedLocation }
                  onSelect={ setSelectedLocation }
                  onShow={ handleShowLocationModal }
                  onShowEdit={ handleShowEditLocationModal }
                  onShowDelete={ handleShowDeleteLocationModal } />
              </div>
            }
          </Can>
          {/* <h3 className="mt-5 mb-2"><a href="/workorders">Työtilaus</a></h3> */}
          <div className="accordionItem">
          <h3 className="mt-2 mb-2">Sopimus</h3>
          <UploadedFiles files={ fileInfos2 } readonly />
          </div>
          <div className="accordionItem">
          <h3 className="mt-2 mb-2">Pohjakuva</h3>
          <UploadedFiles files={ fileInfos3 } readonly />
          </div>
          <div className="accordionItem">
          <h3 className="mt-2 mb-2">Siivoustyötohjeet</h3>
          <UploadedFiles files={ fileInfos4 } readonly />
          </div>
          <Can I="read" a="Location">
          <div className="accordionItem">
          <h3 className="mt-2 mb-2">Siivousaineet</h3>
          <ListGroup>
            { selectedChemicals && selectedChemicals.map((chemical, index) => (
              <ListGroup.Item
                key={ index }>
                <div className="list-group-item-content">
                  <a href={ chemical.url } target="_blank" rel="noopener noreferrer">
                    {chemical.name}
                  </a>
                </div>
              </ListGroup.Item>
            )) }
          </ListGroup>
          <Can I="create" a="Location">
          <span className="mb-0 button" onClick={handleShowChemicalsModal}>Valitse siivousaineet</span>
          </Can>
          </div>
          </Can>
          <Can I="create" a="Location">
          <div className="accordionItem">
          <h3 className="mt-2 mb-2">Siivousvälineet/muuta infoa (sisäinen)</h3>
          <UploadedFiles files={ fileInfos5 } readonly />
          </div>
          </Can>
          <div className="accordionItem">
          <h3 className="mt-2 mb-2">Laadunvalvontaraportit</h3>
          <Can I="read" a="Quality">
                <>
                    { qualities && qualities.map(quality => (
                        <Can I="read" this={quality}>
                            <QualityItem
                                key={ quality.id }
                                quality={ quality }
                                onSelect={ setSelectedQuality }
                                onShow={ handleShowQualityModal } />
                        </Can>
                    )) }
                </>
            </Can>
          </div>
          <Can I="create" a="Location">
          <div className="buttonContainer">
          <div className="vertical-center">
            <span className="mt-4 mb-4 button" onClick={ handleShowUploadModal }>Lisää tiedosto<ActionIcon className="ml-3" icon={ faPlusCircle } /></span>
          </div>
          </div>
          </Can>
        </div>
        <div className="illustration">
          <img src={ illustration } alt="" />
        </div>
      </div>

      <Modal
        title="Kohde"
        show={ showLocationModal }
        onHide={ handleHideLocationModal }
      >
        <LocationItem location={ selectedLocation } />
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideLocationModal }>
            Sulje
          </Button>
        </div>
      </Modal>
      <Modal
        title="Lisää kohde"
        show={ showCreateLocationModal }
        onHide={ handleHideCreateLocationModal }
      >
        <LocationForm
          onCancel={ handleHideCreateLocationModal }
          onSubmit={ handleCreateLocation }
        />
      </Modal>
      <Modal
        title="Muokkaa kohdetta"
        show={ showEditLocationModal }
        onHide={ handleHideEditLocationModal }
      >
        <LocationForm
          location={ selectedLocation }
          isSendingData={ isSendingData }
          onCancel={ handleHideEditLocationModal }
          onSubmit={ handleUpdateLocation }
        />
      </Modal>
      <Modal
        title="Poista kohde"
        show={ showDeleteLocationModal }
        onHide={ handleHideDeleteLocationModal }
      >
        <LocationItem location={ selectedLocation } />
        <p>Haluatko varmasti poistaa tämän kohteen? Tätä toimintoa ei voi peruuttaa.</p>
        <div className="align-right mt-4">
          <Button variant="secondary" onClick={ handleHideDeleteLocationModal }>
            Peruuta
          </Button>
          <Button className="ml-3" variant="danger" onClick={ handleDeleteLocation }>
            Kyllä
          </Button>
        </div>
      </Modal>
      <Modal
        title="Lisää tiedosto"
        show={ showUploadModal }
        onHide={ handleRefreshView }
      >
        <UploadFiles
          onCancel={ handleHideUploadModal } />
      </Modal>
      <Modal
        title="Valitse siivousvälineet"
        show={ showToolsModal }
        onHide={ handleHideToolsModal }
      >
        <LocationTools 
          tools={toolInfos}
          onCancel={ handleHideToolsModal } />
      </Modal>
      <Modal
        title="Valitse siivousaineet"
        show={ showChemicalsModal }
        onHide={ handleHideChemicalsModal }
      >
        <LocationChemicals
          selChemicals={selectedChemicals}
          chemicals={chemicalInfos}
          onCancel={ handleHideChemicalsModal } />
      </Modal>
      <Modal
        title="Valitse työtavat"
        show={ showWorkingwaysModal }
        onHide={ handleHideWorkingwaysModal }
      >
        <LocationWorkingways
          onCancel={ handleHideWorkingwaysModal } />
      </Modal>
      <Modal
        title="Valitse työohjeet"
        show={ showWorkmanualsModal }
        onHide={ handleHideWorkmanualsModal }
      >
        <LocationWorkmanuals
          onCancel={ handleHideWorkmanualsModal } />
      </Modal>
      <Modal
                title="Laadunvalvontaraportti"
                size="lg"
                show={ showQualityModal }
                onHide={ handleRefreshView }
            >
                <QualityItem quality={ selectedQuality } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideQualityModal }>
                        Sulje
                    </Button>
                </div>
            </Modal>

    </Page>
  );
}
