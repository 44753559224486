import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import './css/workorderItem.style.css';

export default function WorkorderItem({ workorder, onSelect, onShow, onShowEdit, onShowDelete }) {
    function handleShow() {
        if (onSelect) onSelect(workorder);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(workorder);
        onShowEdit();
    }

    function handleShowDelete() {
        onSelect(workorder);
        onShowDelete();
    }

    function isUpdated() {
        return moment(workorder.updatedAt).isAfter(moment(workorder.createdAt));
    }

    return (
        <div className="workorder-item">
            <div className="workorder-item-content-wrapper" onClick={ handleShow }>
                <div className="workorder-from-avatar"></div>
                <div className="workorder-item-content">
                    <h6>{ workorder.location.name } - { workorder.title }</h6>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            <p dangerouslySetInnerHTML={{ __html: workorder.message }}></p>
                            <p className="mt-2"><small>Luotu: { moment(workorder.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: { moment(workorder.updatedAt).format('lll') }</small></p> }
                        </> }
                </div>
            </div>
            <div className="workorder-item-actions">
                { onSelect && onShowEdit && <Can I="update" this={workorder}>
                    <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </Can> }
                { onSelect && onShowDelete && <Can I="delete" this={workorder}>
                    <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Can> }
            </div>
        </div>
    );
}
