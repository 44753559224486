import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import placeholder from '../assets/images/kuvitus_cart.png';

import './css/newsItem.style.css';

export default function NewsItem({ item, onSelect, onShow, onShowEdit, onShowDelete, extract = false }) {
    function handleShow() {
        if (onSelect) onSelect(item);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(item);
        onShowEdit();
    }

    function handleShowDelete() {
        onSelect(item);
        onShowDelete();
    }

    function isUpdated() {
        return moment(item.updatedAt).isAfter(moment(item.createdAt));
    }

    return (
        <div className="news-item">
            <div className="news-item-content-wrapper" onClick={ handleShow }>
                <div className={`news-item-${ extract ? 'thumbnail' : 'image' }`}>
                    <img src={ item.titleimg ? item.titleimg : placeholder} alt="" />
                </div>
                <div className="news-item-content">
                    { extract ?
                        <>
                            <h6 className="news-item-extract">{ item.title }</h6>
                            <p><small>Luotu: { moment(item.createdAt).format('l') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: { moment(item.updatedAt).format('l') }</small></p> }
                        </> :
                        <>
                            <h6 className="news-item-heading">{ item.title }</h6>
                            <p className="news-item-description" dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            {
                                <>
                                    <p className="mt-2"><small>Luotu: { moment(item.createdAt).format('lll') }</small></p>
                                    { isUpdated() &&
                                        <p><small>Muokattu: { moment(item.updatedAt).format('lll') }</small></p> }
                                </> }
                        </> }
                </div>
            </div>
            <div className="news-item-actions">
                { onSelect && onShowEdit && <Can I="update" this={item}>
                    <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </Can> }
                { onSelect && onShowDelete && <Can I="delete" this={item}>
                    <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Can> }
            </div>
        </div>
    )
}
