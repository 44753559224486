export default function authHeader() {
  const user = JSON.parse(window.localStorage.getItem('user'));

  if (user && user.accessToken) {
    return { 'x-access-token': user.accessToken,
      "Content-type": "application/json"
    };
  } else {
    return { "Content-type": "application/json" };
  }
}
