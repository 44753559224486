import React, { useEffect, useState } from 'react';
import { subject as a } from '@casl/ability';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from './actionIcon.component';
import AppLoader from './appLoader.component';
import Can from './can.component';
import WorkingwayForm from './forms/workingwayForm.component';
import WorkingwayItem from './workingwayItem.component';
import Modal from './modal.component';
import Page from './page.component';
import WorkingwayDataService from '../services/workingway.service';

import './css/workingways.style.css';

export default function Workingways() {
    const [isSendingData, setIsSendingData] = useState(false);
    const [isFetchingWorkingways, setIsFetchingWorkingways] = useState(true);

    const [workingways, setWorkingways] = useState([]);
    const [selectedWorkingway, setSelectedWorkingway] = useState(null);

    const [showWorkingwayModal, setShowWorkingwayModal] = useState(false);
    const handleShowWorkingwayModal = () => setShowWorkingwayModal(true);
    const handleHideWorkingwayModal = () => setShowWorkingwayModal(false);
    const [showCreateWorkingwayModal, setShowCreateWorkingwayModal] = useState(false);
    const handleShowCreateWorkingwayModal = () => setShowCreateWorkingwayModal(true);
    const handleHideCreateWorkingwayModal = () => setShowCreateWorkingwayModal(false);
    const [showEditWorkingwayModal, setShowEditWorkingwayModal] = useState(false);
    const handleShowEditWorkingwayModal = () => setShowEditWorkingwayModal(true);
    const handleHideEditWorkingwayModal = () => setShowEditWorkingwayModal(false);
    const [showDeleteWorkingwayModal, setShowDeleteWorkingwayModal] = useState(false);
    const handleShowDeleteWorkingwayModal = () => setShowDeleteWorkingwayModal(true);
    const handleHideDeleteWorkingwayModal = () => setShowDeleteWorkingwayModal(false);

    async function handleCreateWorkingway(values) {
      try {
        setIsSendingData(true);
        await WorkingwayDataService.create(values);
        setIsSendingData(false);
        const response = await WorkingwayDataService.getAll();
        const data = response.data.map(workingway => a('Workingway', workingway));
        setWorkingways(data);
        setShowCreateWorkingwayModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleUpdateWorkingway(values) {
      try {
        setIsSendingData(true);
        await WorkingwayDataService.update(selectedWorkingway.id, values);
        setIsSendingData(false);
        const response = await WorkingwayDataService.getAll();
        const data = response.data.map(workingway => a('Workingway', workingway));
        setWorkingways(data);
        setShowEditWorkingwayModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    async function handleDeleteWorkingway() {
      try {
        setIsSendingData(true);
        await WorkingwayDataService.delete(selectedWorkingway.id);
        setSelectedWorkingway(null);
        setIsSendingData(false);
        const response = await WorkingwayDataService.getAll();
        const data = response.data.map(workingway => a('Workingway', workingway));
        setWorkingways(data);
        setShowDeleteWorkingwayModal(false);
      } catch(error) {
        console.log(error);
        setIsSendingData(false);
      }
    }

    useEffect(() => {
      async function getWorkingways() {
        try {
          setIsFetchingWorkingways(true);
          const response = await WorkingwayDataService.getAll();
          const data = response.data.map(workingway => a('Workingway', workingway));
          setWorkingways(data);
          setIsFetchingWorkingways(false);
        } catch(error) {
          console.log(error);
          setIsFetchingWorkingways(false);
        }
      }
      getWorkingways();
    }, []);

    return (
        <Page center>
            <AppLoader active={ isFetchingWorkingways } component={ true } />

            <h2>Työtavat</h2>
            <div
                className="vertical-center mt-5 mb-5"
                style={{
                    marginLeft: '64px'
                }}
            >
                <Can I="create" a="Workingway">
                    <p className="mb-0">Luo työtapa</p>
                    <ActionIcon
                        className="ml-5"
                        icon={ faPlusCircle }
                        onClick={ handleShowCreateWorkingwayModal } />
                </Can>
            </div>
            <Can I="read" a="Workingway">
                <>
                    { workingways && workingways.map(workingway => (
                        <Can I="read" this={workingway}>
                            <WorkingwayItem
                                key={ workingway.id }
                                workingway={ workingway }
                                onSelect={ setSelectedWorkingway }
                                onShow={ handleShowWorkingwayModal }
                                onShowEdit={ handleShowEditWorkingwayModal }
                                onShowDelete={ handleShowDeleteWorkingwayModal } />
                        </Can>
                    )) }
                </>
            </Can>

            <Modal
                title="työtapa"
                size="lg"
                show={ showWorkingwayModal }
                onHide={ handleHideWorkingwayModal }
            >
                <WorkingwayItem workingway={ selectedWorkingway } />
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideWorkingwayModal }>
                        Sulje
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Lisää työtapa"
                size="lg"
                show={ showCreateWorkingwayModal }
                onHide={ handleHideCreateWorkingwayModal }
            >
                <WorkingwayForm
                    isSendingData={ isSendingData }
                    onCancel={ handleHideCreateWorkingwayModal }
                    onSubmit={ handleCreateWorkingway }
                />
            </Modal>
            <Modal
                title="Muokkaa työtapaa"
                size="lg"
                show={ showEditWorkingwayModal }
                onHide={ handleHideEditWorkingwayModal }
            >
                <WorkingwayForm
                    workingway={ selectedWorkingway }
                    isSendingData={ isSendingData }
                    onCancel={ handleHideEditWorkingwayModal }
                    onSubmit={ handleUpdateWorkingway }
                />
            </Modal>
            <Modal
                title="Poista työohje"
                size="lg"
                show={ showDeleteWorkingwayModal }
                onHide={ handleHideDeleteWorkingwayModal }
            >
                <WorkingwayItem workingway={ selectedWorkingway } />
                <p>Haluatko varmasti poistaa tämän työtavan? Tätä toimintoa ei voi peruuttaa.</p>
                <div className="align-right mt-4">
                    <Button variant="secondary" onClick={ handleHideDeleteWorkingwayModal }>
                        Peruuta
                    </Button>
                    <Button className="ml-3" variant="danger" onClick={ handleDeleteWorkingway }>
                        Kyllä
                    </Button>
                </div>
            </Modal>
        </Page>
    );
}
