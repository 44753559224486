import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Can from './can.component';
import './css/chemicalItem.style.css';

export default function ChemicalItem({ chemical, onSelect, onShow, onShowEdit, onShowDelete }) {
    function handleShow() {
        if (onSelect) onSelect(chemical);
        if (onShow) onShow();
    }

    function handleShowEdit() {
        onSelect(chemical);
        onShowEdit();
    }

    function handleShowDelete() {
        onSelect(chemical);
        onShowDelete();
    }

    function isUpdated() {
        return moment(chemical.updatedAt).isAfter(moment(chemical.createdAt));
    }

    return (
        <div className="chemical-item">
            <div className="chemical-item-content-wrapper" onClick={ handleShow }>
                <div className="chemical-from-avatar"></div>
                <div className="chemical-item-content">
                    <h6>{ chemical.name }</h6>
                    { !onSelect && !onShow && !onShowEdit && !onShowDelete &&
                        <>
                            <p dangerouslySetInnerHTML={{ __html: chemical.description }}></p>
                            <p><a href={chemical.url} target="_blank" rel="noreferrer">{chemical.url}</a></p>
                            <p className="mt-2"><small>Luotu: { moment(chemical.createdAt).format('lll') }</small></p>
                            { isUpdated() &&
                                <p><small>Muokattu: { moment(chemical.updatedAt).format('lll') }</small></p> }
                        </> }
                </div>
            </div>
            <div className="chemical-item-actions">
                { onSelect && onShowEdit && <Can I="update" this={chemical}>
                    <Button variant="secondary" size="sm" onClick={ handleShowEdit }>
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </Can> }
                { onSelect && onShowDelete && <Can I="delete" this={chemical}>
                    <Button variant="warning" size="sm" onClick={ handleShowDelete }>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Can> }
            </div>
        </div>
    );
}
